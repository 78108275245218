/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import sortBy from 'lodash/sortBy';
import StoresPageNavigation from '../StoresPageNavigation';
import StoresPageCategoryItem from '../StoresPageCategoryItem';
import s from './BrandsPage.css';
import { Brand } from '../../../../graphql-generated-types';

type PT = {
  brands: Brand[];
};

const BrandsPage: FC<PT> = ({ brands }) => {
  useStyles(s);

  const renderCategories = () => {
    const categories: any = {
      latinStores: {
        pattern: /[A-z\u00C0-\u00ff]+/,
        data: [],
      },
      numsStores: {
        pattern: /[0-9]/,
        data: [],
      },
      cyrillicStores: {
        pattern: /[\u0400-\u04FF]/,
        data: [],
      },
    };

    const updatedCampaign = brands.map(({ name, ...rest }: any) => ({
      ...rest,
      name: name.charAt(0).toUpperCase() + name.slice(1),
      uppercaseName: name.toUpperCase(),
    }));

    const categoryItems: any[] = [];
    Object.keys(categories).forEach(key => {
      let subCategory: any[] = [];
      let lastItem: any = null;

      // Create separate arrs for all categories - cyrillic, latin etc.
      categories[key].data = updatedCampaign.filter(({ name }: any) =>
        categories[key].pattern.test(name.charAt(0)),
      );

      // Sort by alphabet
      categories[key].dataByAlphabet = sortBy(categories[key].data, [
        item => item.uppercaseName,
      ]);

      categories[key].dataByAlphabet.forEach((item: any) => {
        subCategory.push(item);

        if (lastItem !== null && lastItem.name[0] !== item.name[0]) {
          subCategory.pop();
          categoryItems.push(subCategory);
          subCategory = [];
          subCategory.push(item);
        }

        lastItem = item;
      });

      // For the last sub-category
      categoryItems.push(subCategory);
    });

    return (
      <>
        <StoresPageNavigation items={categories} />
        <div className={s.items}>
          {categoryItems.map((items, index) => (
            <StoresPageCategoryItem
              key={index}
              items={items}
              // @ts-ignore
              style={{ zIndex: categoryItems.length - index }}
            />
          ))}
        </div>
      </>
    );
  };

  return (
    <div className={s.root}>
      <h1 className={s.title}>Все магазины</h1>
      {renderCategories()}
    </div>
  );
};

export default BrandsPage;
