import React, { FC } from 'react';
import ReactSelect from 'react-select';

type Option = {
  value: string;
  label: string;
};

type PT = {
  placeholder: string;
  options: Option[];
  error?: boolean;
  errorMessage?: string;
  disabled: boolean;
};

const Select: FC<PT> = ({
  placeholder = '',
  options,
  error,
  errorMessage,
  disabled,
  ...props
}) => {
  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      height: 60,
      borderTop: '1px solid var(--color-light)',
      color: 'var(--color-dark)',
      background:
        state.isSelected || state.isFocused
          ? 'rgba(0, 0, 0, 0.025)'
          : 'transparent',
      padding: '12px 30px',
      fontSize: '0.875rem',
      fontWeight: 'bold',
      cursor: 'pointer',
      WebkitTapHighlightColor: 'transparent',
      ':active': {
        backgroundColor:
          !state.isDisabled && (state.isSelected ? 'rgba(0, 0, 0, 0.025)' : 'rgba(0, 0, 0, 0.025)'),
      },
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: 'flex',
      width: '100%',
      // maxWidth: 350,
      height: 61,
      border: '1px solid var(--color-gray-light)',
      cursor: 'pointer',
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      padding: '12px 30px',
      fontSize: '0.875rem',
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      display: 'none',
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      padding: '20px',
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      margin: '-1px 0 0 0',
      borderRadius: 0,
      boxShadow: 'none',
      border: '1px solid var(--color-gray-light)',
      borderTop: '1px solid var(--color-light)',
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      padding: 0,
      marginTop: -1,
    }),
    noOptionsMessage: (provided: any, state: any) => ({
      ...provided,
      fontSize: '0.875rem',
      color: 'var(--color-gray)',
      fontWeight: 'bold',
      textAlign: 'left',
      paddingLeft: 30,
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: 'var(--color-gray)',
      fontWeight: 'bold',
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      fontWeight: 'bold',
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      fontWeight: 'bold',
    }),
  };

  const errorStyles = {
    margin: '7px 0 0',
    fontSize: '0.75rem',
    color: 'var(--color-error)',
  };

  return (
    <>
      <ReactSelect
        options={options}
        placeholder={placeholder}
        styles={customStyles}
        noOptionsMessage={() => 'Не найдено'}
        isDisabled={disabled}
        {...props}
      />
      {error && <span style={errorStyles}>{errorMessage}</span>}
    </>
  );
};

export default Select;
