import React, { FC, ChangeEvent, useState } from 'react';
import { Field, FieldProps } from 'formik';
import Input from 'components/Input';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './DeliveryCheckbox.css';

type PT = {
  name: string;
  value: string;
  id: string;
  label: string;
  priceValue: string;
};

const DeliveryCheckbox: FC<PT> = ({ id, value, name, label, priceValue }) => {
  useStyles(s);

  const [inputValue, setInputValue] = useState<string>(priceValue);

  function handleChange(
    e: ChangeEvent<HTMLInputElement>,
    fieldValue: any,
    form: any,
    field: any,
  ) {
    const v = e.target.value;

    setInputValue(v);

    const nextValue = field.value.map((x: any) => {
      const o = { ...x };
      if (o.typeName === fieldValue.typeName) o.price = parseFloat(v) || 0;
      return o;
    });

    form.setFieldValue(name, nextValue);
  }

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const fieldValue = field.value.find((x: any) => x.typeName === value);
        const isChecked = !!fieldValue;

        return (
          <div className={s.root}>
            <input
              type="checkbox"
              id={id}
              checked={isChecked}
              onChange={() => {
                if (isChecked) {
                  const nextValue = field.value.filter(
                    (x: any) => x.typeName !== value,
                  );
                  form.setFieldValue(name, nextValue);
                } else {
                  const nextValue = field.value.concat({
                    typeName: value,
                    price: parseFloat(inputValue) || 0,
                    currency: 'BYN',
                    __typename: 'BrandDelivery',
                  });
                  form.setFieldValue(name, nextValue);
                }
              }}
            />
            <label htmlFor={id}>{label}</label>

            {isChecked && (
              <Input
                className={s.priceInput}
                placeholder="Цена"
                name="price"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChange(e, fieldValue, form, field)
                }
                value={inputValue}
              />
            )}
          </div>
        );
      }}
    </Field>
  );
};

export default DeliveryCheckbox;
