import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import ProductList from 'components/ProductList';
import brandQuery from 'modules/brand/queries/brand-query.graphql';
import s from './BrandProducts.css';

type PT = {
  brandId: string;
};

const BrandProducts: FC<PT> = ({ brandId }) => {
  useStyles(s);

  const brandResp = useQuery(brandQuery, {
    variables: { brandId },
    fetchPolicy: 'network-only', // 'cache-and-network',
  });

  const brand = get(brandResp, 'data.brand', null);

  if (!brand) return null;

  const { id, products = [], hiddenProducts = [], maxProductsPerBrand } = brand;

  const totalProducts = products.length + hiddenProducts.length;
  const showAddProductButton = maxProductsPerBrand - totalProducts > 0;

  return (
    <div className={s.root}>
      <ProductList
        products={products}
        // columns="3"
        brandId={id}
        setProductLink={(productId: string) =>
          `/brand/product-edit/${productId}`
        }
        showAddProductButton={showAddProductButton}
      />
      <hr className={s.line} />
      <h2 className={s.hiddenStyleTitle}>Скрытые товары</h2>
      <p className={s.hiddenStyleParagraph}>
        Здесь вы можете хранить товары, которые решите временно не показывать в
        магазине.
      </p>
      <ProductList
        products={hiddenProducts}
        columns={3}
        setProductLink={(pId: string) => `/brand/product-edit/${pId}`}
      />
    </div>
  );
};

export default BrandProducts;
