import React, { FC, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import Button from 'components/Button';
import CKEditor from 'components/CKEditor';
import InfoTooltip from 'components/InfoTooltip';
import FormikCheckbox from '../FormikCheckbox';
import brandSettingsMutation from '../../mutations/brand-settings.mutation.graphql';
import { PAYMENT_TYPES, PAYMENT_TYPES_LABEL } from '../../constants';
import s from './BrandSettingsPaymentForm.css';
import { Brand } from '../../../../graphql-generated-types';

type PT = {
  brand: Brand;
};

type FormValues = {
  paymentInfo: string;
  paymentAllowedTypes: string[];
};

const BrandSettingsPaymentForm: FC<PT> = ({ brand }) => {
  useStyles(s);

  const initialFormValues: FormValues = {
    paymentInfo: brand.paymentInfo || '',
    paymentAllowedTypes: brand.paymentAllowedTypes || [],
  };

  const [isFormError, setFormError] = useState(false);
  const [isSuccess, setFormSuccess] = useState(false);
  const [updateSettings] = useMutation(brandSettingsMutation);

  const onSubmit = async (values: any) => {
    try {
      updateSettings({
        variables: {
          brandId: brand.id,
          brandSettingsData: {
            paymentInfo: values.paymentInfo,
            paymentAllowedTypes: values.paymentAllowedTypes,
          },
        },
      });

      setFormSuccess(true);
      setFormError(false);

      setTimeout(() => {
        setFormSuccess(false);
      }, 2500);
    } catch (error) {
      console.error('Error', error);
      setFormError(true);
      setTimeout(() => {
        setFormError(false);
      }, 5000);
    }
  };

  return (
    <Formik initialValues={initialFormValues} onSubmit={onSubmit}>
      {({
        values,
        // errors,
        // touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        // isSubmitting,
        // isValid,
      }) => {
        return (
          <form className={s.root} onSubmit={handleSubmit}>
            {Object.values(PAYMENT_TYPES).map((typeName: string) => {
              return (
                <section className={s.formSection} key={typeName}>
                  <FormikCheckbox
                    name="paymentAllowedTypes"
                    value={typeName}
                    id={`payment-${typeName.toLocaleLowerCase()}`}
                    // @ts-ignore
                    label={PAYMENT_TYPES_LABEL[typeName]}
                  />
                </section>
              );
            })}
            <div className={s.formSubTitle}>
              <span>Дополнительная информация</span>
              <InfoTooltip text="Расскажите как можно оплатить ваш товар. Опишите условия по каждому выбранному способу. Укажите другие возможные способы оплаты и условия их применения." />
            </div>
            <section className={s.formSection}>
              <CKEditor
                data={values.paymentInfo}
                placeholder="Дополнительная информация"
                onChange={(v: string) => setFieldValue('paymentInfo', v)}
              />
            </section>
            <section className={s.formSection}>
              <div className={s.formSectionControls}>
                <Button type="submit">Сохранить</Button>
                {isSuccess && (
                  <p className={s.formSuccess}>Информация обновлена</p>
                )}
                {isFormError && (
                  <p className={s.formError}>
                    Возникла ошибка. Попробуйте позже.
                  </p>
                )}
              </div>
            </section>
          </form>
        );
      }}
    </Formik>
  );
};

export default BrandSettingsPaymentForm;
