import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import cx from 'classnames';
import Icon from 'components/Icon';
import s from './InfoTooltip.css';

type PT = {
  text: string;
  orientation?: string;
};

const InfoTooltip: FC<PT> = ({ text, orientation = '' }) => {
  useStyles(s);
  return (
    <div className={cx(s.root, { [s[orientation]]: !!orientation })}>
      <Icon icon="info-bold" size="16" viewBox="0 0 7 16" />
      <div className={s.text}>{text}</div>
    </div>
  );
};

export default InfoTooltip;
