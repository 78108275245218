exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2JqD1{position:relative;width:100%}._2JqD1:last-child .x0Sqs{border-bottom:none}._15OEW ._31-z-{display:none}._15OEW:last-child .x0Sqs{border-bottom:2px solid var(--color-light)}.x0Sqs{display:flex;align-items:center;position:relative;height:110px;font-size:14px;font-size:.875rem;font-weight:700;text-transform:uppercase;letter-spacing:var(--letter-spacing);border-top:2px solid var(--color-light);cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}._3g2X4{display:flex;justify-content:center;width:150px}@media (max-width:992px){._3g2X4{width:auto;margin-right:40px}}._40wfK{width:16px;height:2px;margin-left:auto;margin-right:60px;background:var(--color-gray-light)}._31-z-{position:relative;padding-left:150px}@media (max-width:992px){._31-z-{padding:0}}", ""]);
// Exports
exports.locals = {
	"root": "_2JqD1",
	"header": "x0Sqs",
	"collapsed": "_15OEW",
	"content": "_31-z-",
	"icon": "_3g2X4",
	"minusIcon": "_40wfK"
};