import React, { FC } from 'react';

/* eslint-disable jsx-a11y/alt-text */

type ImagePropTypes = {
  webpUrl?: string;
  url?: string;
  alt?: string;
  className?: string;
};

const Image: FC<ImagePropTypes> = ({ webpUrl, url = '', ...props }) => {
  return (
    <picture>
      {webpUrl ? <source srcSet={webpUrl} type="image/webp" /> : null}
      <img src={url !== '' ? url : '/img/placeholder.svg'} {...props} />
    </picture>
  );
};

export default Image;
