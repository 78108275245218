import React, { FC, useState, ReactNode } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import Icon from 'components/Icon';
import cx from 'classnames';
import s from './BrandSettingsTab.css';

type PT = {
  title: string;
  icon: string;
  iconSize: string;
  children: ReactNode;
  collapsed?: boolean;
};

const BrandSettingsTab: FC<PT> = ({
  title,
  icon,
  iconSize,
  collapsed = true,
  children,
}) => {
  useStyles(s);

  const [isCollapsed, setCollapsed] = useState(collapsed);

  return (
    <div className={cx(s.root, { [s.collapsed]: isCollapsed })}>
      <header className={s.header} onClick={() => setCollapsed(!isCollapsed)}>
        {icon && (
          <div className={s.icon}>
            <Icon icon={icon} size={iconSize} />
          </div>
        )}
        {title}
        {!isCollapsed && <i className={s.minusIcon} />}
      </header>
      <div className={s.content}>{children}</div>
    </div>
  );
};

export default BrandSettingsTab;
