exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".rGtci{position:relative}._2V53h{margin:0 0 40px;color:var(--color-gray-light);font-size:14px;font-size:.875rem}._1I-yd{display:flex;justify-content:space-between;margin:0 0 30px}._1sLfy{margin-top:30px;margin-left:auto;text-align:right}@media (max-width:992px){._1sLfy{width:100%;margin-left:0}}._1sLfy button{width:360px}@media (max-width:992px){._1sLfy button{width:100%}}._3vRZa{margin:50px 0 20px;padding:0;font-size:14px;font-size:.875rem;font-weight:700;text-transform:uppercase;letter-spacing:var(--letter-spacing)}._3vRZa span{margin-right:20px}._1jyeF{color:var(--color-error)}._1jyeF,._12FaT{margin:15px 0 0 30px;font-size:14px;font-size:.875rem}._12FaT{color:var(--color-gray)}", ""]);
// Exports
exports.locals = {
	"root": "rGtci",
	"note": "_2V53h",
	"formSection": "_1I-yd",
	"formSectionControls": "_1sLfy",
	"formSubTitle": "_3vRZa",
	"formError": "_1jyeF",
	"formSuccess": "_12FaT"
};