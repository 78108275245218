import React, { FC, ReactChild, ReactPortal } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import brandQuery from 'modules/brand/queries/brand-query.graphql';
import BrandPanelHeader from '../BrandPanelHeader';
import BrandSettingsNavigation from '../BrandSettingsNavigation';
import s from './BrandSettingsPage.css';

type Children = ReactChild | Array<Children> | ReactPortal;

type PT = {
  brandId: string;
  title: string;
  children: ((brand: any) => Children) | Children;
  page: string;
};

const BrandSettingsPage: FC<PT> = ({ brandId, title, page, children }) => {
  useStyles(s);

  const { data } = useQuery(brandQuery, {
    variables: { brandId },
    fetchPolicy: 'cache-and-network',
  });

  const brandData = get(data, 'brand', null);

  if (!brandData) return null;

  return (
    <div className={s.root}>
      <BrandPanelHeader brand={brandData} title={title} />
      <div className={s.content}>
        <aside className={s.aside}>
          <BrandSettingsNavigation page={page} />
        </aside>
        <div className={s.main}>
          {typeof children === 'function' ? children(brandData) : children}
        </div>
      </div>
    </div>
  );
};

export default BrandSettingsPage;
