exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".gx--x{position:relative;width:100%;height:293px;margin-bottom:30px}._1qase{-o-object-fit:cover;object-fit:cover}._3H4rf,._1qase{width:100%;height:100%}._3H4rf{position:relative}._3H4rf:hover .DBmEG{opacity:.6}._3H4rf:hover ._1z2RJ{opacity:1}._1z2RJ{left:0;top:0;width:100%;height:100%;background:rgba(0,0,0,.6)}._1z2RJ,.DBmEG{position:absolute;transition:all .2s ease-out;opacity:0}.DBmEG{top:20px;right:20px;color:#fff;font-size:14px;font-size:.875rem;border:none;background:none;cursor:pointer;outline:none}.DBmEG svg{margin-right:10px}.DBmEG:hover{opacity:1!important}._25lSG{position:relative}._25lSG::-webkit-file-upload-button{visibility:hidden}._25lSG[type=file]{border:0;clip:rect(0,0,0,0);height:1px;overflow:hidden;padding:0;position:absolute!important;white-space:nowrap;width:1px;outline:none}._25lSG[type=file]+label{display:flex;align-items:center;justify-content:center;position:relative;width:100%;height:100%;background-color:var(--color-light);transition:background-color .2s ease-out;cursor:pointer}._25lSG[type=file]+label:hover{background-color:#f3f5f6}._25lSG[type=file]+label:hover ._3fdvu{color:var(--color-dark)}._25lSG[type=file]+label:hover ._3fdvu svg path{stroke:var(--color-dark)}._3fdvu{padding:0 125px;color:var(--color-gray);font-size:14px;font-size:.875rem;text-align:center;transition:all .2s ease-out}@media (max-width:480px){._3fdvu{padding:0 30px}}._3fdvu p{padding:0;margin:0 0 10px}._3fdvu svg{margin-bottom:5px}._3fdvu svg path{stroke:var(--color-gray);transition:all .2s ease-out}._2iTLD{display:flex;align-items:center;justify-content:center;position:relative;width:100%;height:100%;background-color:var(--color-light)}", ""]);
// Exports
exports.locals = {
	"root": "gx--x",
	"img": "_1qase",
	"cover": "_3H4rf",
	"removeButton": "DBmEG",
	"coverShadow": "_1z2RJ",
	"input": "_25lSG",
	"labelBtn": "_3fdvu",
	"loading": "_2iTLD"
};