export const DELIVERY_TYPES = {
  MEETING: 'MEETING',
  TAKE_IN_PLACE: 'TAKE_IN_PLACE',
  POST: 'POST',
  COURIER: 'COURIER',
  WORLD: 'WORLD',
};

export const DELIVERY_TYPES_LABEL = {
  POST: 'Почтой по Беларуси',
  MEETING: 'Личная встреча',
  COURIER: 'Курьером по городу',
  TAKE_IN_PLACE: 'Получение в пункте выдачи',
  WORLD: 'Почтой по миру',
};

export const PAYMENT_TYPES = {
  CASH: 'CASH',
  PAYMENT_ON_DELIVERY: 'PAYMENT_ON_DELIVERY',
  ERIP: 'ERIP',
  CARD: 'CARD',
  WEBMONEY: 'WEBMONEY',
  EASYPAY: 'EASYPAY',
  POST_MONEY_TRANSFER: 'POST_MONEY_TRANSFER',
  BANK_MONEY_TRANSFER: 'BANK_MONEY_TRANSFER',
  // ONLINE: 'ONLINE',
};

export const PAYMENT_TYPES_LABEL = {
  CASH: 'Наличными при получении',
  PAYMENT_ON_DELIVERY: 'Наложенный платеж',
  ERIP: 'Оплата через ЕРИП',
  CARD: 'Банковская пластиковая карта',
  WEBMONEY: 'WebMoney',
  EASYPAY: 'EasyPay',
  POST_MONEY_TRANSFER: 'Почтовый перевод',
  BANK_MONEY_TRANSFER: 'Банковский перевод',
  // ONLINE: 'Онлайн',
};

export const BRAND_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  MODERATED: 'MODERATED',
  HIDDEN: 'HIDDEN',
  DELETED: 'DELETED',
  ON_HOLD: 'ON_HOLD',
};

export const BRAND_STATUS_LABEL = {
  ACTIVE: 'Активен',
  INACTIVE: 'Не активен',
  MODERATED: 'На модерации',
  HIDDEN: 'Скрыт',
  DELETED: 'Удалён',
};

export const BRAND_STATUS_IN_LAW = {
  ARTISAN: 'ARTISAN',
  ENTREPRENEUR: 'ENTREPRENEUR',
};
