exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3S1Wf{margin:0 0 60px}._1ADjJ{margin:60px 0;border-top:1px solid var(--color-light)}._2sjX_{color:var(--color-dark)}.Scoac,._2sjX_{margin:0 0 20px;padding:0;font-size:18px;font-size:1.125rem;font-weight:700;text-transform:uppercase;letter-spacing:var(--letter-spacing)}._2xzdf,.Scoac{color:var(--color-gray)}._2xzdf{padding:0;margin:0 0 40px;font-size:14px;font-size:.875rem}", ""]);
// Exports
exports.locals = {
	"root": "_3S1Wf",
	"line": "_1ADjJ",
	"title": "_2sjX_",
	"hiddenStyleTitle": "Scoac",
	"hiddenStyleParagraph": "_2xzdf"
};