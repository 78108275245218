exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3vsPh{position:relative}.C9Wj4{display:flex;justify-content:space-between;margin:0 0 30px}._1-PQL{margin-top:30px;margin-left:auto;text-align:right}@media (max-width:992px){._1-PQL{width:100%;margin-left:0}}._1-PQL button{width:360px}@media (max-width:992px){._1-PQL button{width:100%}}._1N0G1{margin:50px 0 20px;padding:0;font-size:14px;font-size:.875rem;font-weight:700;text-transform:uppercase;letter-spacing:var(--letter-spacing)}._1N0G1 span{margin-right:20px}._36JJE{color:var(--color-error)}._36JJE,._1BKje{margin:15px 0 0 30px;font-size:14px;font-size:.875rem}._1BKje{color:var(--color-gray)}", ""]);
// Exports
exports.locals = {
	"root": "_3vsPh",
	"formSection": "C9Wj4",
	"formSectionControls": "_1-PQL",
	"formSubTitle": "_1N0G1",
	"formError": "_36JJE",
	"formSuccess": "_1BKje"
};