// ref: https://github.com/stefangabos/world_countries/

export default [
  {
    name: 'Австралия',
  },
  {
    name: 'Австрия',
  },
  {
    name: 'Азербайджан',
  },
  {
    name: 'Албания',
  },
  {
    name: 'Алжир',
  },
  {
    name: 'Ангола',
  },
  {
    name: 'Андорра',
  },
  {
    name: 'Антигуа и Барбуда',
  },
  {
    name: 'Аргентина',
  },
  {
    name: 'Армения',
  },
  {
    name: 'Афганистан',
  },
  {
    name: 'Багамские Острова',
  },
  {
    name: 'Бангладеш',
  },
  {
    name: 'Барбадос',
  },
  {
    name: 'Бахрейн',
  },
  {
    name: 'Белиз',
  },
  {
    name: 'Беларусь',
  },
  {
    name: 'Бельгия',
  },
  {
    name: 'Бенин',
  },
  {
    name: 'Болгария',
  },
  {
    name: 'Боливия',
  },
  {
    name: 'Босния и Герцеговина',
  },
  {
    name: 'Ботсвана',
  },
  {
    name: 'Бразилия',
  },
  {
    name: 'Бруней',
  },
  {
    name: 'Буркина-Фасо',
  },
  {
    name: 'Бурунди',
  },
  {
    name: 'Бутан',
  },
  {
    name: 'Вануату',
  },
  {
    name: 'Великобритания',
  },
  {
    name: 'Венгрия',
  },
  {
    name: 'Венесуэла',
  },
  {
    name: 'Восточный Тимор',
  },
  {
    name: 'Вьетнам',
  },
  {
    name: 'Габон',
  },
  {
    name: 'Гаити',
  },
  {
    name: 'Гайана',
  },
  {
    name: 'Гамбия',
  },
  {
    name: 'Гана',
  },
  {
    name: 'Гватемала',
  },
  {
    name: 'Гвинея',
  },
  {
    name: 'Гвинея-Бисау',
  },
  {
    name: 'Германия',
  },
  {
    name: 'Гондурас',
  },
  {
    name: 'Гренада',
  },
  {
    name: 'Греция',
  },
  {
    name: 'Грузия',
  },
  {
    name: 'Дания',
  },
  {
    name: 'Джибути',
  },
  {
    name: 'Доминика',
  },
  {
    name: 'Доминиканская Республика',
  },
  {
    name: 'ДР Конго',
  },
  {
    name: 'Египет',
  },
  {
    name: 'Замбия',
  },
  {
    name: 'Зимбабве',
  },
  {
    name: 'Израиль',
  },
  {
    name: 'Индия',
  },
  {
    name: 'Индонезия',
  },
  {
    name: 'Иордания',
  },
  {
    name: 'Ирак',
  },
  {
    name: 'Иран',
  },
  {
    name: 'Ирландия',
  },
  {
    name: 'Исландия',
  },
  {
    name: 'Испания',
  },
  {
    name: 'Италия',
  },
  {
    name: 'Йемен',
  },
  {
    name: 'Кабо-Верде',
  },
  {
    name: 'Казахстан',
  },
  {
    name: 'Камбоджа',
  },
  {
    name: 'Камерун',
  },
  {
    name: 'Канада',
  },
  {
    name: 'Катар',
  },
  {
    name: 'Кения',
  },
  {
    name: 'Кипр',
  },
  {
    name: 'Киргизия',
  },
  {
    name: 'Кирибати',
  },
  {
    name: 'КНДР (Корейская Народно-Демократическая Республика)',
  },
  {
    name: 'Китай (Китайская Народная Республика)',
  },
  {
    name: 'Колумбия',
  },
  {
    name: 'Коморы',
  },
  {
    name: 'Коста-Рика',
  },
  {
    name: 'Кот-д’Ивуар',
  },
  {
    name: 'Куба',
  },
  {
    name: 'Кувейт',
  },
  {
    name: 'Лаос',
  },
  {
    name: 'Латвия',
  },
  {
    name: 'Лесото',
  },
  {
    name: 'Либерия',
  },
  {
    name: 'Ливан',
  },
  {
    name: 'Ливия',
  },
  {
    name: 'Литва',
  },
  {
    name: 'Лихтенштейн',
  },
  {
    name: 'Люксембург',
  },
  {
    name: 'Маврикий',
  },
  {
    name: 'Мавритания',
  },
  {
    name: 'Мадагаскар',
  },
  {
    name: 'Северная Македония',
  },
  {
    name: 'Малави',
  },
  {
    name: 'Малайзия',
  },
  {
    name: 'Мали',
  },
  {
    name: 'Мальдивы',
  },
  {
    name: 'Мальта',
  },
  {
    name: 'Марокко',
  },
  {
    name: 'Маршалловы Острова',
  },
  {
    name: 'Мексика',
  },
  {
    name: 'Микронезия',
  },
  {
    name: 'Мозамбик',
  },
  {
    name: 'Молдавия',
  },
  {
    name: 'Монако',
  },
  {
    name: 'Монголия',
  },
  {
    name: 'Мьянма',
  },
  {
    name: 'Намибия',
  },
  {
    name: 'Науру',
  },
  {
    name: 'Непал',
  },
  {
    name: 'Нигер',
  },
  {
    name: 'Нигерия',
  },
  {
    name: 'Нидерланды',
  },
  {
    name: 'Никарагуа',
  },
  {
    name: 'Новая Зеландия',
  },
  {
    name: 'Норвегия',
  },
  {
    name: 'ОАЭ',
  },
  {
    name: 'Оман',
  },
  {
    name: 'Пакистан',
  },
  {
    name: 'Палау',
  },
  {
    name: 'Панама',
  },
  {
    name: 'Папуа — Новая Гвинея',
  },
  {
    name: 'Парагвай',
  },
  {
    name: 'Перу',
  },
  {
    name: 'Польша',
  },
  {
    name: 'Португалия',
  },
  {
    name: 'Республика Конго',
  },
  {
    name: 'Республика Корея',
  },
  {
    name: 'Россия',
  },
  {
    name: 'Руанда',
  },
  {
    name: 'Румыния',
  },
  {
    name: 'Сальвадор',
  },
  {
    name: 'Самоа',
  },
  {
    name: 'Сан-Марино',
  },
  {
    name: 'Сан-Томе и Принсипи',
  },
  {
    name: 'Саудовская Аравия',
  },
  {
    name: 'Эсватини',
  },
  {
    name: 'Сейшельские Острова',
  },
  {
    name: 'Сенегал',
  },
  {
    name: 'Сент-Винсент и Гренадины',
  },
  {
    name: 'Сент-Китс и Невис',
  },
  {
    name: 'Сент-Люсия',
  },
  {
    name: 'Сербия',
  },
  {
    name: 'Сингапур',
  },
  {
    name: 'Сирия',
  },
  {
    name: 'Словакия',
  },
  {
    name: 'Словения',
  },
  {
    name: 'Соломоновы Острова',
  },
  {
    name: 'Сомали',
  },
  {
    name: 'Судан',
  },
  {
    name: 'Суринам',
  },
  {
    name: 'США',
  },
  {
    name: 'Сьерра-Леоне',
  },
  {
    name: 'Таджикистан',
  },
  {
    name: 'Таиланд',
  },
  {
    name: 'Танзания',
  },
  {
    name: 'Того',
  },
  {
    name: 'Тонга',
  },
  {
    name: 'Тринидад и Тобаго',
  },
  {
    name: 'Тувалу',
  },
  {
    name: 'Тунис',
  },
  {
    name: 'Туркмения',
  },
  {
    name: 'Турция',
  },
  {
    name: 'Уганда',
  },
  {
    name: 'Узбекистан',
  },
  {
    name: 'Украина',
  },
  {
    name: 'Уругвай',
  },
  {
    name: 'Фиджи',
  },
  {
    name: 'Филиппины',
  },
  {
    name: 'Финляндия',
  },
  {
    name: 'Франция',
  },
  {
    name: 'Хорватия',
  },
  {
    name: 'ЦАР',
  },
  {
    name: 'Чад',
  },
  {
    name: 'Черногория',
  },
  {
    name: 'Чехия',
  },
  {
    name: 'Чили',
  },
  {
    name: 'Швейцария',
  },
  {
    name: 'Швеция',
  },
  {
    name: 'Шри-Ланка',
  },
  {
    name: 'Эквадор',
  },
  {
    name: 'Экваториальная Гвинея',
  },
  {
    name: 'Эритрея',
  },
  {
    name: 'Эстония',
  },
  {
    name: 'Эфиопия',
  },
  {
    name: 'ЮАР',
  },
  {
    name: 'Южный Судан',
  },
  {
    name: 'Ямайка',
  },
  {
    name: 'Япония',
  },
];
