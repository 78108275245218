import React, { FC, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import cx from 'classnames';
import Icon from 'components/Icon';
import s from './HorizontalTabs.css';

/* eslint-disable react/no-danger */

type TabItem = {
  name: string;
  content: string;
};

type PT = {
  tabsData: [TabItem];
};

const HorizontalTabs: FC<PT> = ({ tabsData }) => {
  useStyles(s);

  const [activeTab, setActiveTab] = useState<boolean>(false);

  return (
    <Tabs className={s.root} selectedTabClassName={s.selected}>
      <div className={s.list}>
        <TabList>
          {tabsData.map(tab => (
            <Tab key={tab.name} onClick={() => setActiveTab(true)}>
              {tab.name}
              <Icon icon="arrow-right" viewBox="0 0 7 10" size="10" />
            </Tab>
          ))}
        </TabList>
      </div>
      <div className={cx(s.panels, { [s.isActive]: activeTab })}>
        {tabsData.map(tab => (
          <TabPanel key={tab.name}>
            <div className={s.panelContent}>
              <header className={s.panelHeaderMobile}>
                <button type="button" onClick={() => setActiveTab(false)}>
                  <Icon icon="arrow-right" viewBox="0 0 7 10" size="10" />
                </button>
                {tab.name}
              </header>
              <article dangerouslySetInnerHTML={{ __html: tab.content }} />
            </div>
          </TabPanel>
        ))}
      </div>
    </Tabs>
  );
};

export default HorizontalTabs;
