import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import cx from 'classnames';
import Link from 'components/Link';
import Icon from 'components/Icon';
import s from './BrandSettingsNavigation.css';

// const tabsData = [
//   {
//     name: 'Заказы',
//     content: 'Заказы',
//   },
//   {
//     name: 'Товары',
//     content: 'Товары',
//   },
//   {
//     name: 'Настройки магазина',
//     content: 'Настройки магазина',
//   },
// ];

const BrandSettingsNavigation: FC<{ page: string }> = ({ page }) => {
  useStyles(s);

  return (
    <div className={s.root}>
      {/*
      <Link className={s.link} to="/">
        Заказы
        <Icon icon="arrow-right" viewBox="0 0 7 10" size="10" />
      </Link>
      */}
      <Link
        className={cx(s.link, { [s.selected]: page === 'brand-products' })}
        to="/brand/products"
      >
        Товары
        <Icon icon="arrow-right" viewBox="0 0 7 10" size="10" />
      </Link>
      <Link
        className={cx(s.link, { [s.selected]: page === 'brand-settings' })}
        to="/brand/settings"
      >
        Настройки магазина
        <Icon icon="arrow-right" viewBox="0 0 7 10" size="10" />
      </Link>
    </div>
  );
};

export default BrandSettingsNavigation;
