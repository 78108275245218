import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './FormikCheckbox.css';

interface PropTypes {
  name: string;
  value: string;
  id: string;
  label: string;
}

const FormikCheckbox: FC<PT> = ({
  id,
  value,
  name,
  label,
}) => {
  useStyles(s);

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const fieldValue = field.value.find((x: any) => x === value);
        const isChecked = !!fieldValue;

        return (
          <div className={s.root}>
            <input
              type="checkbox"
              id={id}
              checked={isChecked}
              onChange={() => {
                if (isChecked) {
                  const nextValue = field.value.filter((x: any) => x !== value);
                  form.setFieldValue(name, nextValue);
                } else {
                  const nextValue = field.value.concat(value);
                  form.setFieldValue(name, nextValue);
                }
              }}
            />
            <label htmlFor={id}>{label}</label>
          </div>
        );
      }}
    </Field>
  );
};

export default FormikCheckbox;
