exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".tqbnx{position:relative}.-JfG-{width:100%;height:450px;-o-object-fit:cover;object-fit:cover;-o-object-position:center;object-position:center}@media (max-width:768px){.-JfG-{height:230px}}._1jIDc{position:relative;margin:40px auto 0;max-width:var(--max-content-width)}.WPvwV{margin-bottom:150px}@media (max-width:768px){.WPvwV{margin-bottom:60px}}.m0jdg{display:flex;align-items:center;position:relative;margin:0 0 70px}@media (max-width:768px){.m0jdg{flex-direction:column}}.m0jdg button{margin-left:auto}._2ryIs{width:190px;height:190px;-o-object-fit:cover;object-fit:cover;-o-object-position:center;object-position:center;border-radius:100%}@media (max-width:768px){._2ryIs{width:120px;height:120px;margin-bottom:20px}}._2C0-s{margin-left:110px}@media (max-width:768px){._2C0-s{display:flex;flex-direction:column;align-items:center;margin:0}}._2C0-s h1{margin:0 0 5px;padding:0;font-weight:700;font-size:36px;font-size:2.25rem}@media (max-width:768px){._2C0-s h1{font-size:1.75rem}}._2C0-s p{padding:0;margin:0;font-size:14px;font-size:.875rem;color:var(--color-gray-light)}._2C0-s p._3nVDr{margin:0 0 10px;color:var(--color-dark)}.OgYU5{position:relative;padding:0 0 0 300px}@media (max-width:768px){.OgYU5{padding:0 10px}}._3VnVS{display:flex;position:relative;margin:95px 0 0;border-top:2px solid var(--color-light)}._2is5f{width:50%;padding:55px 55px 55px 0}._2GOM-{margin:0;padding:0;font-size:14px;font-size:.875rem;color:var(--color-gray)}", ""]);
// Exports
exports.locals = {
	"root": "tqbnx",
	"cover": "-JfG-",
	"centered": "_1jIDc",
	"section": "WPvwV",
	"header": "m0jdg",
	"logo": "_2ryIs",
	"title": "_2C0-s",
	"brandDescription": "_3nVDr",
	"products": "OgYU5",
	"footer": "_3VnVS",
	"footerColumn": "_2is5f",
	"footerText": "_2GOM-"
};