exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1i-CQ{position:relative}.V9Zih{display:flex;justify-content:space-between;margin:0 0 30px}._26rZG{margin-top:30px;margin-left:auto;text-align:right}@media (max-width:992px){._26rZG{width:100%;margin-left:0}}._26rZG button{width:360px}@media (max-width:992px){._26rZG button{width:100%}}._3JIqd{color:var(--color-error)}._3JIqd,._3P8CJ{margin:15px 0 0 30px;font-size:14px;font-size:.875rem}._3P8CJ{color:var(--color-gray)}", ""]);
// Exports
exports.locals = {
	"root": "_1i-CQ",
	"formSection": "V9Zih",
	"formSectionControls": "_26rZG",
	"formError": "_3JIqd",
	"formSuccess": "_3P8CJ"
};