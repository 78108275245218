import React, { FC } from 'react';
import BrandSettingsForm from '../BrandSettingsForm';
import BrandSettingsDeliveryForm from '../BrandSettingsDeliveryForm';
import BrandSettingsPaymentForm from '../BrandSettingsPaymentForm';
import BrandSettingsRulesForm from '../BrandSettingsRulesForm';
import BrandSettingsTab from '../BrandSettingsTab';
import BrandSettingsCover from '../BrandSettingsCover';
import BrandSettingsLawForm from '../BrandSettingsLawForm';
import { Brand } from '../../../../graphql-generated-types';
import { BRAND_STATUS_IN_LAW } from '../../constants';

type PT = {
  brand: Brand;
};

const BrandSettings: FC<PT> = ({ brand }) => {
  if (!brand) return null;

  return (
    <>
      <BrandSettingsCover brandId={brand.id} coverImage={brand.coverImage} />
      <BrandSettingsTab title="Контактная информация" icon="info" iconSize="21">
        <BrandSettingsForm brand={brand} />
      </BrandSettingsTab>
      {brand.statusInLaw === BRAND_STATUS_IN_LAW.ENTREPRENEUR && (
        <BrandSettingsTab
          title="Юридическая информация"
          icon="rules"
          iconSize="24"
        >
          <BrandSettingsLawForm brand={brand} />
        </BrandSettingsTab>
      )}
      <BrandSettingsTab title="Доставка" icon="delivery" iconSize="30">
        <BrandSettingsDeliveryForm brand={brand} />
      </BrandSettingsTab>
      <BrandSettingsTab title="Оплата" icon="pay" iconSize="24">
        <BrandSettingsPaymentForm brand={brand} />
      </BrandSettingsTab>
      <BrandSettingsTab title="Правила магазина" icon="rules" iconSize="24">
        <BrandSettingsRulesForm brand={brand} />
      </BrandSettingsTab>
      {/* <BrandSettingsTab
        title="Управление магазином"
        icon="control"
        iconSize="24"
        collapsed={false}
      >
        Управление магазином
      </BrandSettingsTab> */}
    </>
  );
};

export default BrandSettings;
