exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2hrTQ{position:relative;width:120px;height:120px;border-radius:100%;overflow:hidden}._1Kwx3{-o-object-fit:cover;object-fit:cover;border-radius:100%}._2QZaQ,._1Kwx3{width:100%;height:100%}._2QZaQ{position:relative}._2QZaQ:hover ._1egIK{opacity:.6}._2QZaQ:hover ._3UCq9{opacity:1}._3UCq9{left:0;background:rgba(0,0,0,.6)}._3UCq9,._1egIK{position:absolute;top:0;width:100%;height:100%;transition:all .2s ease-out;opacity:0}._1egIK{display:flex;flex-direction:column;align-items:center;justify-content:center;right:0;margin:0;padding:0;color:#fff;font-size:12px;font-size:.75rem;border:none;background:none;border-radius:100%;cursor:pointer;outline:none}._1egIK svg{margin-bottom:5px}._1egIK:hover{opacity:1!important}._3wwFn{position:relative}._3wwFn::-webkit-file-upload-button{visibility:hidden}._3wwFn[type=file]{border:0;clip:rect(0,0,0,0);height:1px;overflow:hidden;padding:0;position:absolute!important;white-space:nowrap;width:1px;outline:none}._3wwFn[type=file]+label{display:flex;align-items:center;justify-content:center;position:relative;width:100%;height:100%;background-color:var(--color-light);transition:background-color .2s ease-out;cursor:pointer}._3wwFn[type=file]+label:hover{background-color:#f3f5f6}._3wwFn[type=file]+label:hover ._1Repk{color:var(--color-dark)}._3wwFn[type=file]+label:hover ._1Repk svg path{stroke:var(--color-dark)}._1Repk{display:flex;flex-direction:column;align-items:center;justify-content:center;color:var(--color-gray);font-size:12px;font-size:.75rem;text-align:center;transition:all .2s ease-out}._1Repk svg{margin-bottom:5px}._1Repk svg path{stroke:var(--color-gray);transition:all .2s ease-out}._3ptF6{display:flex;align-items:center;justify-content:center;position:relative;width:100%;height:100%;background-color:var(--color-light)}", ""]);
// Exports
exports.locals = {
	"root": "_2hrTQ",
	"img": "_1Kwx3",
	"cover": "_2QZaQ",
	"removeButton": "_1egIK",
	"coverShadow": "_3UCq9",
	"input": "_3wwFn",
	"labelBtn": "_1Repk",
	"loading": "_3ptF6"
};