import React, { useEffect, ChangeEvent, FC, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import cx from 'classnames';
import InfoTooltip from 'components/InfoTooltip';
import s from './Input.css';

type PT = {
  note: string;
  error: any;
  name?: string;
  errorMessage?: string;
  className: string;
  infoTooltipNote: string;
  infoTooltipOrientation: string;
  placeholder: string;
  id: string;
  value: string;
  onChange: ChangeEvent | Function;
  onBlur: Function;
  required?: boolean;
};

const Input: FC<PT> = ({
  note,
  error,
  errorMessage,
  className,
  infoTooltipNote,
  infoTooltipOrientation,
  id,
  value,
  placeholder,
  onChange,
  ...props
}) => {
  useStyles(s);

  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    if (value) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  });

  return (
    <div className={cx(s.root, className, { [s.error]: error })}>
      <input {...props} value={value} onChange={onChange} />
      <label htmlFor={id} className={cx({ [s.isActive]: isActive })}>
        {placeholder}
      </label>
      {note && !error && <span className={s.note}>{note}</span>}
      {error && <span className={s.errorMessage}>{errorMessage}</span>}
      {infoTooltipNote && (
        <div className={s.infoTooltipIcon}>
          <InfoTooltip
            text={infoTooltipNote}
            orientation={infoTooltipOrientation}
          />
        </div>
      )}
    </div>
  );
};

export default Input;
