exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._9IOw5{position:relative}.ODq6J{margin:0 0 40px;color:var(--color-gray-light);font-size:14px;font-size:.875rem}.yCpg0{display:flex;justify-content:space-between;margin:0 0 30px}._1sDan{margin-top:30px;margin-left:auto;text-align:right}@media (max-width:992px){._1sDan{width:100%;margin-left:0}}._1sDan button{width:360px}@media (max-width:992px){._1sDan button{width:100%}}.IO2Ap{margin:50px 0 20px;padding:0;font-size:12px;font-size:.75rem;font-weight:700;text-transform:uppercase;letter-spacing:var(--letter-spacing)}._1yi2i{color:var(--color-error)}._1yi2i,._3ZPIz{margin:15px 0 0 30px;font-size:14px;font-size:.875rem}._3ZPIz{color:var(--color-gray)}.N2FuU{width:100%}._3toyY{width:350px}@media (max-width:992px){._3toyY{width:calc(50% - 10px)}}", ""]);
// Exports
exports.locals = {
	"root": "_9IOw5",
	"note": "ODq6J",
	"formSection": "yCpg0",
	"formSectionControls": "_1sDan",
	"formSubTitle": "IO2Ap",
	"formError": "_1yi2i",
	"formSuccess": "_3ZPIz",
	"input": "N2FuU",
	"inputSmall": "_3toyY"
};