import React, { FC, useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import useStyles from 'isomorphic-style-loader/useStyles';
import 'react-tabs/style/react-tabs.css';
import s from './StoresPageNavigation.css';

type PT = {
  items: any[];
};

const StoresPageNavigation: FC<PT> = ({ items }) => {
  useStyles(s);

  const [tabIndex, setTabIndex] = useState(-1);
  const [isMobile, setIsMobile] = useState(false);

  const detectIfMobileHandler = () => {
    const isM = document.documentElement.clientWidth <= 860;
    if (isMobile !== isM) {
      setIsMobile(isM);
    }
  };

  const detectIfMobile = () => {
    window.addEventListener('resize', detectIfMobileHandler);
  };

  useEffect(() => {
    detectIfMobile();
    detectIfMobileHandler();
  }, []);

  const handleClickOutside = () => setTabIndex(-1);

  const renderNavSection = (dataItems: any) => {
    let currentItem: any;

    return dataItems.map((item: any) => {
      const firstSymbol = item.name.charAt(0);

      if (currentItem === firstSymbol) return null;

      currentItem = firstSymbol;
      return (
        <a
          href={`#${firstSymbol}`}
          className={s.link}
          key={item.name}
          onClick={handleClickOutside}
        >
          {firstSymbol}
        </a>
      );
    });
  };

  return (
    <div className={s.root}>
      <div className={s.categoriesWrapper}>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(i: number) => setTabIndex(i)}
          forceRenderTabPanel={!isMobile}
          className={s.tabs}
        >
          <TabList className={s.tabList}>
            {Object.keys(items).map((key, index) => {
              const dataArr = items[key].dataByAlphabet;

              if (dataArr.length === 0) return null;

              const firstSymbol = dataArr[0].name.charAt(0);
              const lastSymbol = dataArr[dataArr.length - 1].name
                .charAt(0)
                .toLowerCase();

              return (
                <Tab
                  key={key}
                  index={index}
                  className={s.tab}
                  selectedClassName={s.selectedTab}
                >
                  <button type="button" className={s.categoryButton}>
                    {firstSymbol} - {lastSymbol}
                  </button>
                </Tab>
              );
            })}
          </TabList>
          <div className={s.tabPanelWrapper}>
            {Object.keys(items).map((key: string) => (
              <TabPanel
                key={key}
                className={s.tabPanel}
                selectedClassName={s.selectedTabPanel}
              >
                <div className={s.navigationWrapper}>
                  <div className={s.scrollWrapper}>
                    <div className={s.categoryContainer}>
                      {renderNavSection(items[key].dataByAlphabet)}
                    </div>
                  </div>
                </div>
              </TabPanel>
            ))}
          </div>
        </Tabs>
      </div>
    </div>
  );
};

export default StoresPageNavigation;
