import React, { FC, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import Input from 'components/Input';
import Button from 'components/Button';
import CKEditor from 'components/CKEditor';
import Select from 'components/Select';
import InfoTooltip from 'components/InfoTooltip';
import countries from 'data/fixtures/countries';
import validate from './validate';
import BrandSettingsLogo from '../BrandSettingsLogo';
import brandSettingsMutation from '../../mutations/brand-settings.mutation.graphql';
import s from './BrandSettingsForm.css';
import { Brand } from '../../../../graphql-generated-types';

type PT = {
  brand: Brand;
};

type FormValues = {
  name: string;
  city: string;
  country: string;
  // address: string;
  phone: string;
  email: string;
  telegram: string;
  viber: string;
  whatsapp: string;
  description: string;
  shortDescription: string;
  slug: string;
  ownerName: string;
  instagram: string;
};

const BrandSettingsForm: FC<PT> = ({ brand }) => {
  useStyles(s);

  let instInitial = '';
  if (brand.socials) {
    const item = brand.socials.find(x => x.name === 'instagram');
    if (item) instInitial = item.url;
  }

  const initialFormValues: FormValues = {
    name: brand.name || '',
    city: brand.city || '',
    country: brand.country || '',
    // address: brand.address || '',
    phone: brand.phone || '',
    email: brand.email || '',
    description: brand.description || '',
    slug: brand.slug || '',
    ownerName: brand.ownerName || '',
    instagram: instInitial,
    telegram: brand.telegram || '',
    viber: brand.viber || '',
    whatsapp: brand.whatsapp || '',
    shortDescription: brand.shortDescription || '',
  };

  const [isFormError, setFormError] = useState(false);
  const [isSuccess, setFormSuccess] = useState(false);
  const [updateSettings] = useMutation(brandSettingsMutation);

  const telNumSanitize = (tel: string) => tel.replace(/\W/g, '');

  const onSubmit = async (values: any) => {
    let socialsToUpdate: any[] = [];
    if (brand.socials) {
      const item = brand.socials.find(x => x.name === 'instagram');
      if (item) {
        const res = brand.socials
          .filter(x => x.name !== 'instagram')
          .map(x => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { __typename, ...rest } = x;
            return { ...rest };
          });
        socialsToUpdate = [
          ...res,
          { name: 'instagram', url: values.instagram },
        ];
      }
    }

    try {
      await updateSettings({
        variables: {
          brandId: brand.id,
          brandSettingsData: {
            name: values.name,
            city: values.city,
            // country: values.country,
            // address: values.address,
            phone: `${values.phone !== '' ? `+${telNumSanitize(values.phone)}` : ''}`,
            email: values.email,
            description: values.description,
            shortDescription: values.shortDescription,
            ownerName: values.ownerName,
            telegram: values.telegram,
            viber: `${values.viber !== '' ? `+${telNumSanitize(values.viber)}` : ''}`,
            whatsapp: `${values.whatsapp !== '' ? `+${telNumSanitize(values.whatsapp)}` : ''}`,
            socials: socialsToUpdate,
            // slug: values.slug,
          },
        },
      });

      setFormSuccess(true);
      setFormError(false);

      setTimeout(() => {
        setFormSuccess(false);
      }, 2500);
    } catch (error) {
      console.error('Error: ', error);
      setFormError(true);
      setTimeout(() => {
        setFormError(false);
      }, 5000);
    }
  };

  const options = countries.map(({ name }: any) => ({
    value: name,
    label: name,
  }));

  return (
    <Formik
      initialValues={initialFormValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        // isSubmitting,
        // isValid,
      }) => {
        return (
          <form className={s.root} onSubmit={handleSubmit}>
            <section className={s.formSection}>
              <BrandSettingsLogo brandId={brand.id} logo={brand.logo} />
            </section>
            <section className={s.formSection}>
              <Input
                className={s.input}
                name="name"
                placeholder="Название магазина*"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={errors.name && touched.name}
                errorMessage={errors.name}
                required
              />
            </section>
            <section className={s.formSection}>
              <Input
                className={s.input}
                name="city"
                placeholder="Город"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.city}
                error={errors.city && touched.city}
                errorMessage={errors.city}
              />
              <Select
                className={s.select}
                placeholder="Выберите страну"
                options={options}
                value={
                  (options
                    ? options.find(({ value }: any) => value === values.country)
                    : '') as any
                }
                onChange={({ value }: any) => setFieldValue('country', value)}
                disabled
              />
            </section>
            {/* <section className={s.formSection}>
              <Input
                className={s.inputLarge}
                name="address"
                placeholder="Адрес*"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.address}
                error={errors.address && touched.address}
                errorMessage={errors.address}
                required
              />
            </section> */}
            <section className={s.formSection}>
              <Input
                type="tel"
                name="phone"
                className={s.input}
                placeholder="Телефон*"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                error={errors.phone && touched.phone}
                errorMessage={errors.phone}
                infoTooltipNote="На этот номер будут приходить оповещения о новых заказах"
                required
              />
              <Input
                type="email"
                className={s.input}
                name="email"
                placeholder="Email*"
                autoComplete="on"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={errors.email && touched.email}
                errorMessage={errors.email}
                required
              />
            </section>
            <section className={s.formSection}>
              <Input
                className={s.input}
                name="telegram"
                placeholder="Никнейм в telegram"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.telegram}
                error={errors.telegram && touched.telegram}
                errorMessage={errors.telegram}
              />
              <Input
                className={s.input}
                name="viber"
                placeholder="Телефон в viber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.viber}
                error={errors.viber && touched.viber}
                errorMessage={errors.viber}
              />
            </section>
            <section className={s.formSection}>
              <Input
                className={s.input}
                name="whatsapp"
                placeholder="Телефон в whatsapp"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.whatsapp}
                error={errors.whatsapp && touched.whatsapp}
                errorMessage={errors.whatsapp}
              />
              <Input
                className={s.input}
                name="instagram"
                placeholder="Никнейм в instagram"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.instagram}
                error={errors.instagram && touched.instagram}
                errorMessage={errors.instagram}
                required
              />
            </section>
            <section className={s.formSection}>
              <Input
                className={s.inputLarge}
                name="ownerName"
                placeholder="ФИО*"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.ownerName}
                error={errors.ownerName && touched.ownerName}
                errorMessage={errors.ownerName}
                required
              />
            </section>
            <section className={s.formSection}>
              <Input
                className={s.inputLarge}
                name="shortDescription"
                placeholder="Короткое описание (не более 140 символов)"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.shortDescription}
                error={errors.shortDescription && touched.shortDescription}
                errorMessage={errors.shortDescription}
              />
            </section>
            <div className={s.formSubTitle}>
              <span>Описание вашего бренда</span>
              <InfoTooltip text="Расскажите историю и факты о своем бренде. Когда и кем основан? Почему так называется? Что вдохновляет? Какие материалы используете и почему? Какие товары самые популярные? В чем ваша фишка? Куда стремитесь? и тд. Размер – один, два абзаца по три, четыре предложения." />
            </div>
            <section className={s.formSection}>
              <CKEditor
                data={values.description}
                placeholder="Описание вашего бренда"
                onChange={(v: string) => setFieldValue('description', v)}
              />
            </section>
            {/* <section className={s.formSection}>
              <Input
                className={s.inputLarge}
                name="slug"
                placeholder="Уникальное имя"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.slug}
                error={errors.slug && touched.slug}
                errorMessage={errors.slug}
                infoTooltipNote="Уникальное имя используется для доступа к вашему магазину на сайте. Изменить уникальное имя можно через обращение в службу поддержки"
                infoTooltipOrientation="left"
                disabled
              />
            </section> */}
            <footer className={s.formSection}>
              <div className={s.formSectionControls}>
                <Button type="submit">Сохранить</Button>
                {isSuccess && (
                  <p className={s.formSuccess}>Информация обновлена</p>
                )}
                {isFormError && (
                  <p className={s.formError}>
                    Возникла ошибка. Попробуйте позже.
                  </p>
                )}
              </div>
            </footer>
          </form>
        );
      }}
    </Formik>
  );
};

export default BrandSettingsForm;
