/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, ChangeEvent, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useMutation } from '@apollo/client';
import cx from 'classnames';
import Icon from 'components/Icon';
import Image from 'components/Image';
import Loading from 'components/Loading';
import productQuery from 'modules/product/queries/product.query.graphql';
import uploadProductImageMutation from './upload-product-image.mutation.graphql';
import deleteProductImageMutation from './delete-product-image.mutation.graphql';
import setProductDefaultImageMutation from './set-product-default-image.mutation.graphql';
import s from './BrandProductImage.css';
import { ProductImage } from '../../../../graphql-generated-types';

// ref: https://github.com/JakeHartnell/react-images-upload/blob/master/src/component/index.js

interface PT {
  productId: string;
  brandId: string;
  image: ProductImage;
  title?: string;
  setDefaultProductImage: Function;
  isMain: boolean;
}

const BrandProductImage: FC<PT> = ({
  productId,
  brandId,
  image,
  title,
  setDefaultProductImage,
  isMain,
}) => {
  useStyles(s);

  // const increasedLimitBrandId = '60ebe6d1a05f547cf60c0730'; // Brand: SLOW. wild card
  const MB_SIZE = 1024000;
  const sizeLimit = MB_SIZE * 5; // brandId === increasedLimitBrandId ? MB_SIZE * 5 : MB_SIZE * 2;
  const imageUrl = image.fallbackUrl ?? '';

  const [uploadedImage, setUploadedImage] = useState(imageUrl);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [uploadImage] = useMutation(uploadProductImageMutation);
  const [deleteImage] = useMutation(deleteProductImageMutation);
  const [setDefaultImage] = useMutation(setProductDefaultImageMutation);

  const handleChange = async (e: ChangeEvent) => {
    const {
      target: {
        // @ts-ignore
        validity,
        // @ts-ignore
        files: [file],
      },
    } = e;

    if (!file) return;

    if (file.size > sizeLimit)
      return alert(`Размер файла не должен превышать ${sizeLimit / 1000}KB`);

    if (validity.valid && productId) {
      setLoading(true);

      await uploadImage({
        variables: { file, productId },
        update: (_, { data: { uploadProductImage } }) => {
          setUploadedImage(uploadProductImage);
          setLoading(false);
        },
      });
    }
  };

  // Add proper type
  const handleDeleteButtonClick = (event: any) => {
    event.preventDefault();

    deleteImage({
      variables: { productId, imageUrl: uploadedImage },
      update: () => { setUploadedImage(''); },
    });
  };

  const handleImageClick = (event: any) => {
    event.preventDefault();

    setDefaultImage({
      variables: { productId, imageUrl: image.fallbackUrl },
      update: (cache, { data: { setProductDefaultImage } }) => {
        if (setProductDefaultImage) {
          const data: any = cache.readQuery({
            query: productQuery,
            variables: { id: productId },
          });

          const { product } = data;

          if (product) {
            cache.writeQuery({
              query: productQuery,
              variables: { id: productId },
              data: {
                product: {
                  ...product,
                  images: product.images.map((img: ProductImage) => ({
                    url: img.url,
                    fallbackUrl: img.fallbackUrl,
                    orderNum: img.orderNum,
                    isMain: img.fallbackUrl === image.fallbackUrl,
                  })),
                },
              },
            });

            // Use local state to handle update behaviour.
            // Because product originally returned from server
            // Check parent component for details
            setDefaultProductImage(image.fallbackUrl);
          }
        }
      },
    });
  };

  return (
    <div className={s.root}>
      {isLoading ? (
        <div className={s.loading}>
          <Loading />
        </div>
      ) : (
        <>
          {uploadedImage === '' ? (
            <>
              <input
                type="file"
                id="file-product-upload"
                accept="image/png, image/jpeg"
                className={s.input}
                onChange={handleChange}
              />
              <label htmlFor="file-product-upload">
                <div className={s.labelBtn}>
                  <Icon icon="plus" size="21" viewBox="0 0 21 20" />
                </div>
              </label>
            </>
          ) : (
            <div className={cx(s.cover, { [s.main]: isMain })}>
              <Image
                className={s.img}
                url={uploadedImage}
                onClick={handleImageClick}
              />
              <button
                type="button"
                className={s.removeButton}
                title="Удалить фото"
                onClick={handleDeleteButtonClick}
              >
                <Icon icon="close-large" viewBox="0 0 42 42" size="13" />
              </button>
              {title && <p className={s.title}>{title}</p>}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BrandProductImage;
