import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import Select from 'components/Select';
import s from './ProductCategorySelect.css';

type Options = {
  value: string | number;
  label: string;
};

type PT = {
  value: string;
  placeholder: string;
  onChange: Function;
  options: Options[];
};

const ProductCategorySelect: FC<PT> = ({
  value,
  options,
  onChange,
  placeholder,
}) => {
  useStyles(s);

  return (
    <div className={s.root}>
      <Select
        className={s.select}
        placeholder={placeholder}
        options={options}
        value={
          // eslint-disable-next-line no-nested-ternary
          value
            ? options
              ? options.find((item: any) => item.value === value)
              : null
            : null
        }
        onChange={({ value }: any) => onChange(value)}
      />
    </div>
  );
};

export default ProductCategorySelect;
