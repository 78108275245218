exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._35H-j{display:inline-block;position:relative;min-width:100px}._35H-j input{padding:12px 30px;margin:0;width:100%;color:var(--color-dark);font-size:14px;font-size:.875rem;line-height:2.5;border:1px solid var(--color-input-border);-webkit-appearance:none;outline:none}._35H-j input:focus{border:1px solid var(--color-input-focus-border)}@media (max-width:768px){._35H-j input{font-size:1rem}}._35H-j label{position:absolute;left:31px;color:var(--color-gray);font-size:14px;font-size:.875rem;transform:translateY(20px);transform-origin:top left;transition:all .2s ease-out;pointer-events:none}._35H-j:focus-within label{font-size:10px;font-size:.625rem;transform:translateY(6px)}._1rpCO{font-size:10px!important;font-size:.625rem!important;transform:translateY(6px)!important}._1R2wa input{border:1px solid var(--color-error)}._1IqHi{color:var(--color-gray-light)}.i9TBY,._1IqHi{position:absolute;left:30px;right:30px;top:calc(100% - 3px);margin:7px 0 0;font-size:12px;font-size:.75rem;overflow:hidden;white-space:nowrap}.i9TBY{color:var(--color-error)}.fgUuv{position:absolute;width:30px;height:30px;right:10px;top:20px}", ""]);
// Exports
exports.locals = {
	"root": "_35H-j",
	"isActive": "_1rpCO",
	"error": "_1R2wa",
	"note": "_1IqHi",
	"errorMessage": "i9TBY",
	"infoTooltipIcon": "fgUuv"
};