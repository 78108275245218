exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._5OPG5{display:flex;margin:0 0 50px}.slc0L,._5OPG5{position:relative}.slc0L{width:280px;margin:0 20px 0 0}.R7sZ5{margin:0;padding:0;font-size:18px;font-size:1.125rem;font-weight:700;text-transform:uppercase;letter-spacing:var(--letter-spacing)}@media (max-width:992px){.R7sZ5{display:none}}._1XvRx{margin:0 0 10px;font-size:18px;font-size:1.125rem;font-weight:700;text-transform:uppercase;letter-spacing:var(--letter-spacing)}._3Slnb{position:relative;font-size:14px;font-size:.875rem}.yoWSA{font-weight:400;color:var(--color-gray)}._3ajKG{font-weight:700}._18E3J{color:var(--color-green)}._1QtTx{margin-left:auto;text-decoration:none;color:#fff}._1QtTx button{width:100px;min-width:auto!important;height:40px!important;font-size:10px!important;font-size:.625rem!important}@media (max-width:480px){._1QtTx button{width:80px!important}}", ""]);
// Exports
exports.locals = {
	"root": "_5OPG5",
	"aside": "slc0L",
	"title": "R7sZ5",
	"name": "_1XvRx",
	"status": "_3Slnb",
	"statusLabel": "yoWSA",
	"statusValue": "_3ajKG",
	"statusActive": "_18E3J",
	"logoutButton": "_1QtTx"
};