const IMG_PROXY_URL = 'https://images.platto.ai';

const urlSafeBase64 = (string: string) => {
  return Buffer.from(string)
    .toString('base64')
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
};

interface Params {
  url: string;
  width: string;
  height: string;
  extension?: string;
}

const getSignedResizedUrl = ({
  url,
  width,
  height,
}: Params) => {
  const devicePixelRatio = typeof window !== 'undefined' ? window.devicePixelRatio : 1;
  const dpr = devicePixelRatio >= 2 ? 2 : 1; // allow only 2 or 1 values
  const encoded_url = urlSafeBase64(url);
  return `${IMG_PROXY_URL}/?width=${width}&height=${height}&dpr=${dpr}&image=${encoded_url}`;
};

export default getSignedResizedUrl;
