/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useState, ChangeEvent } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import get from 'lodash/get';
import { useMutation } from '@apollo/client';
import Icon from 'components/Icon';
import Image from 'components/Image';
import Loading from 'components/Loading';
import { getSignedResizedUrl } from 'utils';
import brandQuery from '../../queries/brand-query.graphql';
import uploadBrandCoverMutation from './upload-brand-cover.mutation.graphql';
import deleteBrandCoverMutation from './delete-brand-cover.mutation.graphql';
import s from './BrandSettingsCover.css';

type BrandCover = {
  fallbackUrl: string;
  url: string;
};

type PT = {
  brandId: string;
  coverImage: BrandCover;
};

const BrandSettingsCover: FC<PT> = ({ brandId, coverImage }) => {
  useStyles(s);

  const sizeLimit = 1024000; // 1MB

  const [isLoading, setLoading] = useState<boolean>(false);

  const [uploadCover] = useMutation(uploadBrandCoverMutation);
  const [deleteCover] = useMutation(deleteBrandCoverMutation);

  const handleChange = async (e: ChangeEvent) => {
    const {
      target: {
        // @ts-ignore
        validity,
        // @ts-ignore
        files: [file],
      },
    } = e;

    if (!file) return;

    // eslint-disable-next-line no-alert
    if (file.size > sizeLimit) return alert(`Размер файла не должен превышать ${sizeLimit / 1000}KB`);

    if (validity.valid && brandId) {
      setLoading(true);

      await uploadCover({
        variables: { file, brandId },
        update: (cache, { data: { uploadBrandCover } }) => {
          const data: any = cache.readQuery({
            query: brandQuery,
            variables: { brandId },
          });

          const { brand } = data;

          if (brand && uploadBrandCover) {
            cache.writeQuery({
              query: brandQuery,
              variables: { brandId },
              data: {
                brand: {
                  ...brand,
                  coverImage: {
                    __typename: 'BrandCover',
                    url: '',
                    fallbackUrl: uploadBrandCover,
                  },
                },
              },
            });
          }

          setLoading(false);
        },
      });
    }
  };

  const handleDeleteButtonClick = () => {
    deleteCover({
      variables: { brandId },
      update: (cache, { data: { deleteBrandCover } }) => {
        const data: any = cache.readQuery({
          query: brandQuery,
          variables: { brandId },
        });

        const { brand } = data;

        if (brand && deleteBrandCover) {
          cache.writeQuery({
            query: brandQuery,
            variables: { brandId },
            data: {
              brand: {
                ...brand,
                coverImage: {
                  __typename: 'BrandCover',
                  url: '',
                  fallbackUrl: '',
                },
              },
            },
          });
        }
      },
    });
  };

  const url = get(coverImage, 'url', '');
  const fallbackUrl = get(coverImage, 'fallbackUrl', '');

  return (
    <div className={s.root}>
      {isLoading ? (
        <div className={s.loading}>
          <Loading />
        </div>
      ) : (
        <>
          {fallbackUrl === '' ? (
            <>
              <input
                type="file"
                id="file-cover-upload"
                accept="image/png, image/jpeg"
                className={s.input}
                onChange={handleChange}
              />
              <label htmlFor="file-cover-upload">
                <div className={s.labelBtn}>
                  <Icon icon="plus" size="21" viewBox="0 0 21 20" />
                  <p>Добавить обложку</p>
                  <p>
                    Используйте изображение с соотношением сторон 3:1. <br />{' '}
                    Рекомендуемый размер: 1440х450 пикселей и не более 1 MB
                  </p>
                </div>
              </label>
            </>
          ) : (
            <div className={s.cover}>
              <Image
                className={s.img}
                url={getSignedResizedUrl({
                  url: fallbackUrl,
                  width: '900',
                  height: '293',
                  extension: 'jpg',
                })}
              />
              <div className={s.coverShadow} />
              <button
                type="button"
                className={s.removeButton}
                onClick={handleDeleteButtonClick}
              >
                <Icon icon="close-large" viewBox="0 0 42 42" size="20" />{' '}
                Удалить обложку
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BrandSettingsCover;
