import React, { FC, useState, useMemo } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Formik } from 'formik';
import get from 'lodash/get';
import { useMutation, useQuery } from '@apollo/client';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Input from 'components/Input';
import Checkbox from 'components/Checkbox';
import InfoTooltip from 'components/InfoTooltip';
import CKEditor from 'components/CKEditor';
import ProductCategorySelect from 'components/ProductCategorySelect';
import updateProductMutation from 'modules/product/mutations/update-product.mutation.graphql';
import deleteProductMutation from 'modules/product/mutations/delete-product.mutation.graphql';
import { categoryTreeSearch } from 'utils';
import productQuery from 'modules/product/queries/product.query.graphql';
import { PRODUCT_STATUS } from 'modules/product/constants';
import brandQuery from '../../queries/brand-query.graphql';
import categoryFullTreeQuery from '../../queries/category-full-tree.query.graphql';
import BrandProductImage from '../BrandProductImage/BrandProductImage';
import s from './BrandProductForm.css';
import {
  Product,
  Brand,
  ProductImage,
} from '../../../../graphql-generated-types';
import history from '../../../../history';

type PT = {
  product: Product;
  brand: Brand;
};

type FormValues = {
  name: string;
  price: string | any;
  priceWithDiscount: string | any;
  description: string | any;
  category: string;
  public: boolean;
};

const BrandProductForm: FC<PT> = ({ product, brand }) => {
  useStyles(s);

  const { ACTIVE, HIDDEN } = PRODUCT_STATUS;

  const { name, description, price, priceWithDiscount, status } = product;

  const { maxImagesPerProduct } = brand;

  const initialFormValues: FormValues = {
    name,
    price: price || '',
    priceWithDiscount: priceWithDiscount || '',
    description,
    category: '',
    public: status === ACTIVE, // use config
  };

  const [defaultProductImage, setDefaultProductImage] = useState();

  const [isFormError, setFormError] = useState<boolean>(false);

  const categories = product.category.split(',');
  const [level1Category, setLevel1Category] = useState<string | null>(categories[1] || null);
  const [level2Category, setLevel2Category] = useState<string | null>(categories[2] || null);
  const [level3Category, setLevel3Category] = useState<string | null>(categories[3] || null);

  // product images
  const images = product?.images ?? [];

  const productImages: any[] = useMemo(() => {
    const numberOfFreeSlots = maxImagesPerProduct - (images?.length ?? 0);
    const slots = numberOfFreeSlots > 0
      ? Array.from(Array(numberOfFreeSlots)).map(_ => ({
          __typename: "ProductImage",
          url: '',
          fallbackUrl: '',
          orderNum: 0,
          isMain: false,
        }))
      : [];

    return [...images, ...slots];
  }, [images, maxImagesPerProduct]);

  const [mutate] = useMutation(updateProductMutation);
  const [removeProduct] = useMutation(deleteProductMutation);

  // load categories
  const { data } = useQuery(categoryFullTreeQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const categoryFullTree = get(data, 'categoryFullTree', []);

  const level1 = categoryFullTree.find((x: any) => x.id === level1Category);
  const level1Options = categoryFullTree.map((item: any) => ({
    value: item.id,
    label: item.name,
  }));

  const level2Options = level1
    ? level1.children.map((x: any) => ({ value: x.id, label: x.name }))
    : [];

  const level2 =
    level2Category !== ''
      ? categoryTreeSearch(categoryFullTree, level2Category)
      : undefined;
  const level3Options =
    level2 && level2.children
      ? level2.children.map((x: any) => ({ value: x.id, label: x.name }))
      : [];

  const getProductCategoryString = () => {
    let str = '';

    str = level1Category && level1Category !== '' ? `,${level1Category},` : ``;
    str =
      level2Category && level2Category !== ''
        ? `${str}${level2Category},`
        : `${str}`;
    str =
      level3Category && level3Category !== ''
        ? `${str}${level3Category},`
        : `${str}`;

    return str;
  };

  const handleProductDelete = async () => {
    try {
      await removeProduct({
        variables: { id: product.id },
        update: (cache, { data: { deleteProduct } }) => {
          if (!deleteProduct) return;

          const dataFromCache: any = cache.readQuery({
            query: brandQuery,
            variables: { brandId: brand.id },
          });

          cache.writeQuery({
            query: brandQuery,
            variables: { brandId: brand.id },
            data: {
              brand: {
                ...dataFromCache.brand,
                products: dataFromCache.brand.products.filter(
                  (p: Product) => p.id !== product.id,
                ),
                hiddenProducts: dataFromCache.brand.hiddenProducts.filter(
                  (p: Product) => p.id !== product.id,
                ),
              },
            },
          });

          // delete defect from cache
          // Ref: https://github.com/apollographql/apollo-feature-requests/issues/4
          // @ts-ignore
          Object.keys(cache.data.data).forEach(
            // @ts-ignore
            (key: string) => key.match(/Product/) && cache.data.delete(key),
          );

          setFormError(false);

          // redirect to product list
          history.push('/brand/products');
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = async (values: any) => {
    try {
      // refetch catalog tree only if product public status changed
      const isPublicChanged =
        (status === HIDDEN && values.public === true) ||
        (status === ACTIVE && values.public === false);

      const refetchQueries = isPublicChanged
        ? [{ query: categoryFullTreeQuery }]
        : [];

      await mutate({
        variables: {
          productData: {
            productId: product.id,
            name: values.name,
            description: values.description,
            price: parseFloat(values.price),
            priceWithDiscount: parseFloat(values.priceWithDiscount),
            // @ts-ignore
            brandId: product.brand.id,
            category: getProductCategoryString(),
            status: values.public === true ? ACTIVE : HIDDEN,
          },
        },
        refetchQueries,
        update: (cache, { data: { updateProduct } }) => {
          if (!updateProduct) return;

          cache.writeQuery({
            query: productQuery,
            variables: { id: updateProduct.id },
            data: { product: { ...updateProduct } },
          });

          setFormError(false);

          // redirect to product list
          history.push('/brand/products');
        },
      });
    } catch (error) {
      console.error('Error: ', error);
      setFormError(true);
      setTimeout(() => setFormError(false), 5000);
    }
  };

  const isImageMain = (img: ProductImage) => {
    return defaultProductImage && defaultProductImage !== ''
      ? img.fallbackUrl === defaultProductImage
      : img.isMain;
  };

  return (
    <Formik initialValues={initialFormValues} onSubmit={onSubmit}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => {
        return (
          <form className={s.root} onSubmit={handleSubmit}>
            <section className={s.section}>
              <h2 className={s.sectionTitle}>
                <span>1</span>Фотографии
              </h2>

              <div className={s.sectionContent}>
                <a
                  href="/photo-requirements"
                  target="_blank"
                  className={s.photoInfoLink}
                >
                  <Icon icon="doc-bold" size="16" viewBox="0 0 16 19" />{' '}
                  Требования к фотографиям
                </a>
                <div className={s.images}>
                  {productImages.map((image: ProductImage, index: number) => (
                    <BrandProductImage
                      key={index}
                      productId={product.id}
                      brandId={brand.id}
                      image={image}
                      title={isImageMain(image) ? 'Главное фото' : undefined}
                      isMain={isImageMain(image)}
                      setDefaultProductImage={setDefaultProductImage}
                    />
                  ))}
                </div>

                <article className={s.note}>
                  <p>
                    Максимальный размер: <strong>2 MB</strong>
                  </p>
                  <p>
                    Разрешение: не меньше <strong>600px</strong> x{' '}
                    <strong>1500px</strong>
                  </p>
                  <p>
                    Формат: <strong>jpeg, png</strong>
                  </p>
                  <p>
                    Запрещены ссылки, логотипы и другие надписи на фототографии
                  </p>
                </article>
              </div>
            </section>

            <section className={s.section}>
              <h2 className={s.sectionTitle}>
                <span>2</span>Описание товара &nbsp;&nbsp;&nbsp;
                <InfoTooltip text="Подробно опишите свой товар. Укажите его характеристики: размер, состав, материалы, цвет, гендер, назначение, сезон и тд. Расскажите про его особенности и преимущества. Обозначьте сроки изготовления, если делаете на заказ и любые другие нюансы. Характеристики указывайте лучше списком, а информацию разделяйте на небольшие абзацы по смыслу." />
              </h2>
              <div className={s.sectionContent}>
                <section className={s.formSection}>
                  <Input
                    className={s.inputLarge}
                    name="name"
                    placeholder="Название*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    error={errors.name && touched.name}
                    errorMessage={errors.name}
                    infoTooltipNote={`Пишите развернутое уникальное название не меньше трех слов. Отразите в нем особенности товара: рисунок, цвет, материал, особенность кроя, гендерную принадлежность, назначение, способ изготовления и др. Например: "Летнее платье в горошек для девочки", "Керамическая тарелка "Stone"", "Бежевый свитер из 100% шерсти ручной вязки", "Домашняя гранола с клюквой", "Прикроватный столик из массива дуба".`}
                    infoTooltipOrientation="left"
                    required
                  />
                </section>
                <section className={s.formSection}>
                  <Input
                    className={s.inputMedium}
                    name="price"
                    placeholder="Цена*"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.price}
                    error={errors.price && touched.price}
                    errorMessage={errors.price}
                    required
                  />
                  <Input
                    className={s.inputMedium}
                    name="priceWithDiscount"
                    placeholder="Цена со скидкой"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.priceWithDiscount}
                    error={
                      errors.priceWithDiscount && touched.priceWithDiscount
                    }
                    errorMessage={errors.priceWithDiscount}
                  />
                </section>
                <section className={s.formSection}>
                  <CKEditor
                    data={values.description}
                    placeholder="Описание товара"
                    note="Минимум 140 знаков – максимум 1500"
                    onChange={(v: string) => setFieldValue('description', v)}
                  />
                </section>
              </div>
            </section>

            <section className={s.section}>
              <h2 className={s.sectionTitle}>
                <span>3</span>Категория &nbsp;&nbsp;&nbsp;
                <InfoTooltip text="Посмотрите внимательно все уровни категорий и выберите наиболее подходящую для вашего товара. Если совсем ничего не подходит, пишите на lunamarketby@gmail.com. В письме расскажите что за товар, прикрепите фотографию и предложите свое название категории." />
              </h2>
              <p className={s.sectionWarning}>
                Обязательно укажите все три категории
              </p>
              <div className={s.sectionContent}>
                <p className={s.noteSmall}>
                  <a
                    href="/categories"
                    target="_blank"
                    className={s.photoInfoLink}
                  >
                    <Icon icon="doc-bold" size="16" viewBox="0 0 16 19" />{' '}
                    Смотреть все категории
                  </a>
                </p>
                <section className={s.formSection}>
                  <ProductCategorySelect
                    value={level1Category}
                    options={level1Options}
                    placeholder="Выберите категорию 1-го уровня"
                    onChange={(category: any) => {
                      setLevel1Category(category);
                      if (category !== level1Category) {
                        setLevel2Category(null);
                        setLevel3Category(null);
                      }
                    }}
                  />
                </section>
                {level1Category && level2Options.length > 0 && (
                  <section className={s.formSection}>
                    <ProductCategorySelect
                      value={level2Category}
                      options={level2Options}
                      placeholder="Выберите категорию 2-го уровня"
                      onChange={(category: any) => {
                        setLevel2Category(category);
                        if (category !== level2Category)
                          setLevel3Category(null);
                      }}
                    />
                  </section>
                )}
                {level2Category && level3Options.length > 0 && (
                  <section className={s.formSection}>
                    <ProductCategorySelect
                      value={level3Category}
                      options={level3Options}
                      placeholder="Выберите категорию 3-го уровня"
                      onChange={(category: any) => setLevel3Category(category)}
                    />
                  </section>
                )}
              </div>
            </section>

            <section className={s.section}>
              <h2 className={s.sectionTitle}>
                <span>4</span> Публикация
              </h2>
              <div className={s.sectionContent}>
                <section className={s.formSection}>
                  <Checkbox
                    label="Опубликован"
                    name="public"
                    checked={values.public}
                    onChange={(v: boolean) => setFieldValue('public', v)}
                  />
                </section>
              </div>
            </section>

            <section className={s.section}>
              <h2 className={s.sectionTitle}>
                <span>5</span> Удаление
              </h2>
              <div className={s.sectionContent}>
                <section className={s.formSection}>
                  <button
                    type="button"
                    className={s.removeButton}
                    onClick={handleProductDelete}
                  >
                    <Icon icon="trash-bin" viewBox="0 0 22 23" size="18" />
                    Удалить товар
                  </button>
                </section>
              </div>
            </section>

            <footer className={s.formSection}>
              <div className={s.formSectionControls}>
                <Button type="submit">Сохранить изменения</Button>
                {isFormError && (
                  <p className={s.formError}>
                    Возникла ошибка. Попробуйте позже.
                  </p>
                )}
              </div>
            </footer>
          </form>
        );
      }}
    </Formik>
  );
};

export default BrandProductForm;
