/* eslint-disable global-require */
import React, { FC, useEffect, useRef, useState } from 'react';
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import useStyles from 'isomorphic-style-loader/useStyles';
import InfoTooltip from 'components/InfoTooltip';
import cx from 'classnames';
import s from './CKEditor.css';

// ref: https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html
// use with ssr: https://stackoverflow.com/a/58576912/3542893

type PT = {
  note: string;
  className: string;
  data: string;
  placeholder: string;
  infoTooltipNote: string;
  infoTooltipOrientation: string;
  onChange: Function;
};

const config = {
  removePlugins: ['Autoformat'],
  toolbar: [
    'heading',
    '|',
    'bold',
    'italic',
    '|',
    'bulletedList',
    'numberedList',
  ],
};

const CKEditorComponent: FC<PT> = ({
  data = '',
  onChange,
  note,
  placeholder,
  className,
  infoTooltipNote,
  infoTooltipOrientation,
}) => {
  useStyles(s);

  const editorRef = useRef();
  const [editorLoaded, setEditorLoaded] = useState(false);
  // @ts-ignore
  const { CKEditor, ClassicEditor } = editorRef.current || {};

  useEffect(() => {
    // @ts-ignore
    editorRef.current = {
      CKEditor: require('@ckeditor/ckeditor5-react'),
      ClassicEditor: require('@ckeditor/ckeditor5-build-classic'),
    };
    setEditorLoaded(true);
  }, []);

  return (
    <fieldset className={cx(s.root, className)}>
      {editorLoaded && (
        <CKEditor
          editor={ClassicEditor}
          data={data}
          config={placeholder ? { ...config, placeholder } : config}
          onChange={(event: any, editor: any) => onChange(editor.getData())}
          onBlur={(event: any, editor: any) => onChange(editor.getData())}
        />
      )}
      {note && <div className={s.note}>{note}</div>}
      {infoTooltipNote && (
        <div className={s.infoTooltipIcon}>
          <InfoTooltip
            text={infoTooltipNote}
            orientation={infoTooltipOrientation}
          />
        </div>
      )}
    </fieldset>
  );
};

export default CKEditorComponent;
