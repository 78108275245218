import React, { FC, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import Button from 'components/Button';
import CKEditor from 'components/CKEditor';
import InfoTooltip from 'components/InfoTooltip';
import DeliveryCheckbox from '../DeliveryCheckbox';
import brandSettingsMutation from '../../mutations/brand-settings.mutation.graphql';
import { DELIVERY_TYPES, DELIVERY_TYPES_LABEL } from '../../constants';
import s from './BrandSettingsDeliveryForm.css';
import { Brand, BrandDelivery } from '../../../../graphql-generated-types';

type PT = {
  brand: Brand;
};

type FormValues = {
  deliveryInfo: string;
  delivery: BrandDelivery[];
};

const BrandSettingsDeliveryForm: FC<PT> = ({ brand }) => {
  useStyles(s);

  const initialFormValues: FormValues = {
    deliveryInfo: brand.deliveryInfo || '',
    delivery: brand.delivery || [],
  };

  const [isFormError, setFormError] = useState(false);
  const [isSuccess, setFormSuccess] = useState(false);
  const [updateSettings] = useMutation(brandSettingsMutation);

  const onSubmit = async (values: any) => {
    try {
      updateSettings({
        variables: {
          brandId: brand.id,
          brandSettingsData: {
            deliveryInfo: values.deliveryInfo,
            delivery: values.delivery.map((x: BrandDelivery) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { __typename, ...rest } = x;
              return { ...rest };
            }),
          },
        },
      });

      setFormSuccess(true);
      setFormError(false);

      setTimeout(() => {
        setFormSuccess(false);
      }, 2500);
    } catch (error) {
      console.error('Error', error);
      setFormError(true);
      setTimeout(() => {
        setFormError(false);
      }, 5000);
    }
  };

  return (
    <Formik initialValues={initialFormValues} onSubmit={onSubmit}>
      {({ values, handleSubmit, setFieldValue }) => {
        return (
          <form className={s.root} onSubmit={handleSubmit}>
            <div className={s.note}>
              Отметьте возможные варианты доставки ваших товаров
            </div>
            {Object.values(DELIVERY_TYPES).map((typeName: string) => {
              const item = values.delivery.find(x => x.typeName === typeName);
              const priceValue = item && item.price ? item.price : '';

              return (
                <section className={s.formSection} key={typeName}>
                  <DeliveryCheckbox
                    name="delivery"
                    value={typeName}
                    priceValue={priceValue}
                    id={`delivery-${typeName.toLocaleLowerCase()}`}
                    // @ts-ignore
                    label={DELIVERY_TYPES_LABEL[typeName]}
                  />
                </section>
              );
            })}
            <div className={s.formSubTitle}>
              <span>Дополнительная информация</span>
              <InfoTooltip text="Дайте покупателю понять как бысто он может получить свою покупку. Опишите условия по каждому способу доставки: точную или примерную стоимость и сроки доставки. Сколько времени в среднем необходимо, чтобы подготовить посылку к отправке? Укажите адреса, по которым покупатель может забрать товар самостоятельно." />
            </div>
            <section className={s.formSection}>
              <CKEditor
                data={values.deliveryInfo}
                placeholder="Дополнительная информация"
                onChange={(v: string) => setFieldValue('deliveryInfo', v)}
              />
            </section>
            <section className={s.formSection}>
              <div className={s.formSectionControls}>
                <Button type="submit">Сохранить</Button>
                {isSuccess && (
                  <p className={s.formSuccess}>Информация обновлена</p>
                )}
                {isFormError && (
                  <p className={s.formError}>
                    Возникла ошибка. Попробуйте позже.
                  </p>
                )}
              </div>
            </section>
          </form>
        );
      }}
    </Formik>
  );
};

export default BrandSettingsDeliveryForm;
