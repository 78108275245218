import React, { FC, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import Button from 'components/Button';
import Textarea from 'components/Textarea';
import Input from 'components/Input';
import brandSettingsMutation from '../../mutations/brand-settings.mutation.graphql';
import s from './BrandSettingsLawForm.css';
import { Brand, BrandDelivery } from '../../../../graphql-generated-types';

type PT = {
  brand: Brand;
};

type FormValues = {
  nameInLaw: string;
  addressInLaw: string;
  regNum: string;
  regDate: string;
  unp: string;
  regDepartment: string;
  tradeDepartmentRegDate: string;
  lawBookAddress: string;
  workingHours: string;
  orderProcessingHours: string;
};

const BrandSettingsLawForm: FC<PT> = ({ brand }) => {
  useStyles(s);

  const initialFormValues: FormValues = {
    nameInLaw: brand.nameInLaw || '',
    addressInLaw: brand.addressInLaw || '',
    regNum: brand.regNum || '',
    regDate: brand.regDate || '',
    unp: brand.unp || '',
    regDepartment: brand.regDepartment || '',
    tradeDepartmentRegDate: brand.tradeDepartmentRegDate || '',
    lawBookAddress: brand.lawBookAddress || '',
    workingHours: brand.workingHours || '',
    orderProcessingHours: brand.orderProcessingHours || '',
  };

  const [isFormError, setFormError] = useState(false);
  const [isSuccess, setFormSuccess] = useState(false);
  const [updateSettings] = useMutation(brandSettingsMutation);

  const onSubmit = async (values: any) => {
    try {
      updateSettings({
        variables: {
          brandId: brand.id,
          brandSettingsData: {
            nameInLaw: values.nameInLaw,
            addressInLaw: values.addressInLaw,
            regNum: values.regNum,
            regDate: values.regDate,
            unp: values.unp,
            regDepartment: values.regDepartment,
            tradeDepartmentRegDate: values.tradeDepartmentRegDate,
            lawBookAddress: values.lawBookAddress,
            workingHours: values.workingHours,
            orderProcessingHours: values.orderProcessingHours,
          },
        },
      });

      setFormSuccess(true);
      setFormError(false);

      setTimeout(() => {
        setFormSuccess(false);
      }, 2500);
    } catch (error) {
      console.error('Error', error);
      setFormError(true);
      setTimeout(() => {
        setFormError(false);
      }, 5000);
    }
  };

  return (
    <Formik initialValues={initialFormValues} onSubmit={onSubmit}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        // isSubmitting,
        // isValid,
      }) => {
        return (
          <form className={s.root} onSubmit={handleSubmit}>
            <div className={s.formSubTitle}>Юридическое наименование</div>
            <section className={s.formSection}>
              <Input
                className={s.input}
                name="nameInLaw"
                placeholder="Юридическое наименование *"
                onChange={handleChange}
                onBlur={handleBlur}
                note="Пример: ИП Иванов Иван Иванович"
                value={values.nameInLaw}
                error={errors.nameInLaw && touched.nameInLaw}
                errorMessage={errors.nameInLaw}
                required
              />
            </section>
            <div className={s.formSubTitle}>Юридический адрес</div>
            <section className={s.formSection}>
              <Input
                className={s.input}
                name="addressInLaw"
                placeholder="Юридический адрес *"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.addressInLaw}
                error={errors.addressInLaw && touched.addressInLaw}
                errorMessage={errors.addressInLaw}
                required
              />
            </section>
            <div className={s.formSubTitle}>Регистрация</div>
            <section className={s.formSection}>
              <Input
                name="regNum"
                className={s.inputSmall}
                placeholder="Номер свидетельства о гос. регистрации *"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.regNum}
                error={errors.regNum && touched.regNum}
                errorMessage={errors.regNum}
                required
              />
              <Input
                className={s.inputSmall}
                name="regDate"
                placeholder="Дата выдачи свидетельства о гос. рег. *"
                onChange={handleChange}
                onBlur={handleBlur}
                note="дд.мм.гггг"
                value={values.regDate}
                error={errors.regDate && touched.regDate}
                errorMessage={errors.regDate}
                required
              />
            </section>
            <section className={s.formSection}>
              <Input
                name="unp"
                className={s.inputSmall}
                placeholder="УНП *"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.unp}
                error={errors.unp && touched.unp}
                errorMessage={errors.unp}
                required
              />
              <Input
                name="regDepartment"
                className={s.inputSmall}
                placeholder="Регистрирующий орган *"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.regDepartment}
                error={errors.regDepartment && touched.regDepartment}
                errorMessage={errors.regDepartment}
                required
              />
            </section>
            <section className={s.formSection}>
              <Input
                name="tradeDepartmentRegDate"
                className={s.input}
                placeholder="Дата регистрации в торговом реестре"
                onChange={handleChange}
                onBlur={handleBlur}
                note="дд.мм.гггг"
                value={values.tradeDepartmentRegDate}
                error={
                  errors.tradeDepartmentRegDate &&
                  touched.tradeDepartmentRegDate
                }
                errorMessage={errors.tradeDepartmentRegDate}
              />
            </section>
            <div className={s.formSubTitle}>Режим работы</div>
            <section className={s.formSection}>
              <Input
                className={s.input}
                name="workingHours"
                placeholder="Режим работы *"
                onChange={handleChange}
                onBlur={handleBlur}
                note="Заполните режим работы по дням недели"
                value={values.workingHours}
                error={errors.workingHours && touched.workingHours}
                errorMessage={errors.workingHours}
                required
              />
            </section>
            <div className={s.formSubTitle}>Режим обработки заказов</div>
            <section className={s.formSection}>
              <Input
                className={s.input}
                name="orderProcessingHours"
                placeholder="Режим обработки заказов *"
                onChange={handleChange}
                onBlur={handleBlur}
                note="Заполните время обработки заказов, если оно отличается от режима работы."
                value={values.orderProcessingHours}
                error={
                  errors.orderProcessingHours && touched.orderProcessingHours
                }
                errorMessage={errors.orderProcessingHours}
                required
              />
            </section>
            <div className={s.formSubTitle}>
              Адрес книги замечаний и предложений
            </div>
            <section className={s.formSection}>
              <Input
                name="lawBookAddress"
                className={s.input}
                placeholder="Адрес книги замечаний и предложений"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lawBookAddress}
                error={errors.lawBookAddress && touched.lawBookAddress}
                errorMessage={errors.lawBookAddress}
              />
            </section>
            {/* <section className={s.formSection}>
              <CKEditor
                data={values.deliveryInfo}
                placeholder="Дополнительная информация"
                infoTooltipNote="Дайте покупателю понять как бысто он может получить свою покупку. Опишите условия по каждому способу доставки: точную или примерную стоимость и сроки доставки. Сколько времени в среднем необходимо, чтобы подготовить посылку к отправке? Укажите адреса, по которым покупатель может забрать товар самостоятельно."
                infoTooltipOrientation="left"
                onChange={(v: string) => setFieldValue('deliveryInfo', v)}
              />
            </section> */}
            <section className={s.formSection}>
              <div className={s.formSectionControls}>
                <Button type="submit">Сохранить</Button>
                {isSuccess && (
                  <p className={s.formSuccess}>Информация обновлена</p>
                )}
                {isFormError && (
                  <p className={s.formError}>
                    Возникла ошибка. Попробуйте позже.
                  </p>
                )}
              </div>
            </section>
          </form>
        );
      }}
    </Formik>
  );
};

export default BrandSettingsLawForm;
