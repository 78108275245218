import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import cx from 'classnames';
import Link from 'components/Link';
import Button from 'components/Button';
import s from './BrandPanelHeader.css';
import { BRAND_STATUS_LABEL, BRAND_STATUS } from '../../constants';
import { Brand } from '../../../../graphql-generated-types';

type PT = {
  brand: Brand;
  title: string;
};

const BrandPanelHeader: FC<PT> = ({ brand, title = '' }) => {
  useStyles(s);

  const { name, status } = brand;

  return (
    <header className={s.root}>
      <aside className={s.aside}>
        <h2 className={s.name}>{name}</h2>
        <div className={s.status}>
          <span className={s.statusLabel}>Статус:</span>{' '}
          <span
            className={cx(s.statusValue, {
              [s.statusActive]: BRAND_STATUS.ACTIVE === status,
            })}
          >
            {BRAND_STATUS_LABEL[status]}
          </span>
        </div>
      </aside>
      <h1 className={s.title}>{title}</h1>
      <Link to="/signout" className={s.logoutButton}>
        <Button>Выход</Button>
      </Link>
    </header>
  );
};

export default BrandPanelHeader;
