import React from 'react';
import get from 'lodash/get';
import { JsonLd } from 'react-schemaorg';
import { Brand } from 'schema-dts';
import { BrandPage } from 'modules/brand/components';
import Layout from 'components/Layout';
import brandQuery from 'modules/brand/queries/brand-query.graphql';
import { stripHtml } from 'utils';

async function action({ params, client }: RouteAction) {
  const { data } = await client.query({
    query: brandQuery,
    variables: { slug: String(params.slug) },
    fetchPolicy: 'network-only',
  });

  const brand = get(data, 'brand');

  if (!brand) return { redirect: '/not-found' };

  const name = get(brand, 'name', '');
  const description = get(brand, 'shortDescription', '');
  const imageUrl = brand.coverImage ? brand.coverImage.fallbackUrl : '';
  const canonicalUrl = `https://nalune.by/b/${brand.slug}`;

  return {
    title: name,
    description,
    imageUrl,
    canonicalUrl,
    chunks: ['brand'],
    component: (
      <Layout>
        <JsonLd<Brand>
          item={{
            '@context': 'https://schema.org',
            '@type': 'Brand',
            name,
            description: description ? stripHtml(description) : '',
            logo: brand.logo ? brand.logo.fallbackUrl : '',
          }}
        />
        <BrandPage brand={brand} />
      </Layout>
    ),
  };
}

export default action;
