import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Checkbox.css';

type PT = {
  label: string;
  name: string;
  value: string;
  checked: boolean;
  onChange: Function;
};

const Checkbox: FC<PT> = ({ label, name, value, checked, onChange }) => {
  useStyles(s);

  const handleInputChange = (event: any) => {
    onChange(event.target.checked);
  };

  return (
    <div className={s.root}>
      <input
        type="checkbox"
        id={name}
        name={name}
        value={value}
        defaultChecked={checked}
        onChange={handleInputChange}
      />
      <label htmlFor={name}>{label}</label>
    </div>
  );
};

export default Checkbox;
