import React, { FC, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import Button from 'components/Button';
import CKEditor from 'components/CKEditor';
import brandSettingsMutation from '../../mutations/brand-settings.mutation.graphql';
import s from './BrandSettingsRulesForm.css';
import { Brand } from '../../../../graphql-generated-types';

type PT = {
  brand: Brand;
};

type FormValues = {
  rulesInfo: string;
};

const BrandSettingsRulesForm: FC<PT> = ({ brand }) => {
  useStyles(s);

  const initialFormValues: FormValues = {
    rulesInfo: brand.rulesInfo || '',
  };

  const [isFormError, setFormError] = useState(false);
  const [isSuccess, setFormSuccess] = useState(false);
  const [updateSettings] = useMutation(brandSettingsMutation);

  const onSubmit = async (values: any) => {
    try {
      updateSettings({
        variables: {
          brandId: brand.id,
          brandSettingsData: {
            rulesInfo: values.rulesInfo,
          },
        },
      });

      setFormSuccess(true);
      setFormError(false);

      setTimeout(() => {
        setFormSuccess(false);
      }, 2500);
    } catch (error) {
      console.error('Error', error);
      setFormError(true);
      setTimeout(() => {
        setFormError(false);
      }, 5000);
    }
  };

  return (
    <Formik initialValues={initialFormValues} onSubmit={onSubmit}>
      {({
        values,
        // errors,
        // touched,
        // handleChange,
        // handleBlur,
        handleSubmit,
        setFieldValue,
        // isSubmitting,
        // isValid,
      }) => {
        return (
          <form className={s.root} onSubmit={handleSubmit}>
            <section className={s.formSection}>
              <CKEditor
                data={values.rulesInfo}
                placeholder="Правила возврата и обмена, другая важная информация"
                infoTooltipNote="Обозначьте условия обмена и возврата товаров. Укажите какие товары не подлежат этой процедуре. В течении какого времени после получения к вам можно обратиться? Как решаете вопрос товаров с дефектами? Кто оплачивает стоимость доставки возврата? В каком состоянии должен вернуться к вам товар? Предупредите, если не принимаете возвраты или обмены."
                infoTooltipOrientation="left"
                onChange={(v: string) => setFieldValue('rulesInfo', v)}
              />
            </section>
            <section className={s.formSection}>
              <div className={s.formSectionControls}>
                <Button type="submit">Сохранить</Button>
                {isSuccess && (
                  <p className={s.formSuccess}>Информация обновлена</p>
                )}
                {isFormError && (
                  <p className={s.formError}>
                    Возникла ошибка. Попробуйте позже.
                  </p>
                )}
              </div>
            </section>
          </form>
        );
      }}
    </Formik>
  );
};

export default BrandSettingsRulesForm;
