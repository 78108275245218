import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './StoresPageCategoryItem.css';

type PT = {
  items: any[];
};

const StoresPageCategoryItem: FC<PT> = ({ items, ...props }) => {
  useStyles(s);

  if (!items[0]) return null;

  const current = items[0].name.charAt(0);

  return (
    <section className={s.root} {...props}>
      <h2 id={current} className={s.letter}>
        {current}
      </h2>
      <ul className={s.items}>
        {items.map((item: any) => (
          <li key={item.id}>
            <a href={`/b/${item.slug}`} className={s.item} rel="prefetch">
              {item.name}
            </a>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default StoresPageCategoryItem;
