import { emailValidateFormat } from 'utils';

export default function(values: any) {
  const errors: any = {};

  const isNotUndefined = (value: any) => typeof value !== 'undefined';
  const isStringEmpty = (value: string) => value.trim() === '';
  const isFieldEmpty = (value: any) => isNotUndefined(value) && isStringEmpty(value);

  // email
  if (isFieldEmpty(values.email)) {
    errors.email = 'Заполните поле';
  } else if (emailValidateFormat(values.email)) {
    errors.email = 'Неверный формат';
  }

  // name
  if (isFieldEmpty(values.name)) errors.name = 'Заполните имя';

  // city
  if (isFieldEmpty(values.city)) errors.city = 'Заполните город';

  // phone
  if (isFieldEmpty(values.phone)) errors.phone = 'Заполните номер телефона';

  // address
  if (isFieldEmpty(values.address)) errors.address = 'Заполните адрес';

  return errors;
}
