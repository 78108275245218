import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
// import TextButton from 'components/TextButton';
import HorizontalTabs from 'components/HorizontalTabs';
import Image from 'components/Image';
import ProductList from 'components/ProductList';
import { getSignedResizedUrl } from 'utils';
import {
  BRAND_STATUS_IN_LAW,
  DELIVERY_TYPES_LABEL,
  PAYMENT_TYPES_LABEL,
  BRAND_STATUS,
} from '../../constants';
import s from './BrandPage.css';

import { Brand } from '../../../../graphql-generated-types';

type PT = {
  brand: Brand;
};

const BrandPage: FC<PT> = ({ brand }) => {
  useStyles(s);

  const {
    name,
    products,
    description,
    delivery,
    paymentAllowedTypes,
    deliveryInfo,
    paymentInfo,
    rulesInfo,
    country,
    city,
    coverImage,
    logo,
    status,
    statusInLaw,
    addressInLaw,
    unp,
    nameInLaw,
    workingHours,
    orderProcessingHours,
    lawBookAddress,
    regNum,
    regDate,
    regDepartment,
    tradeDepartmentRegDate,
    ownerName,
    shortDescription,
  } = brand;

  let deliveryContent = '';
  if (delivery && delivery.length > 0) {
    deliveryContent = `<h3>Доставка</h3><ul>`;
    delivery.forEach(d => {
      deliveryContent += `
      <li>${DELIVERY_TYPES_LABEL[d.typeName]} ${
        d.price ? `${d.price} ${d.currency}` : ''
      }</li>`;
    });
    deliveryContent += '</ul>';
  }

  let paymentContent = '';
  if (paymentAllowedTypes && paymentAllowedTypes.length > 0) {
    paymentContent = `<br/><h3>Оплата</h3><ul>`;
    paymentAllowedTypes.forEach((t: string) => {
      paymentContent += `<li>${PAYMENT_TYPES_LABEL[t]}</li>`;
    });
    paymentContent += '</ul>';
  }

  const tabsData = [];
  if (description) tabsData.push({ name: 'О магазине', content: description });
  if (deliveryInfo)
    tabsData.push({
      name: 'Доставка и оплата',
      content: `${deliveryContent} ${deliveryInfo} ${paymentContent} ${paymentInfo}`,
    });
  if (rulesInfo)
    tabsData.push({ name: 'Правила магазина', content: rulesInfo });
  if (statusInLaw !== BRAND_STATUS_IN_LAW.ARTISAN) {
    const content = `
      <p>${nameInLaw} <br/> ${unp && `УНП: ${unp}`} <br/> ${addressInLaw &&
      `Юр. адрес: ${addressInLaw}`} <br/> ${regNum &&
      `Свидетельство о регистрации ${regNum}`} ${regDate &&
      `от ${regDate}`} <br /> ${regDepartment &&
      `Регистрирующий орган: ${regDepartment}`} <br/> ${tradeDepartmentRegDate &&
      `Зарегистрирован в торговом реестре: ${tradeDepartmentRegDate}`}</p>
      ${workingHours && `<p>Время работы: ${workingHours}</p>`}
      ${orderProcessingHours &&
        `<p>Время обработки заказов: ${orderProcessingHours}</p>`}
      ${
        lawBookAddress
          ? `<p>Адрес книги замечаний и предложений: ${lawBookAddress}</p>`
          : ''
      }
    `;
    tabsData.push({ name: 'Покупателям', content });
  }

  return (
    <div className={s.root}>
      {coverImage?.fallbackUrl !== '' && (
        <Image
          className={s.cover}
          webpUrl={getSignedResizedUrl({
            url: coverImage?.fallbackUrl ?? '',
            width: '1440',
            height: '450',
            extension: 'webp',
          })}
          url={getSignedResizedUrl({
            url: coverImage?.fallbackUrl ?? '',
            width: '1440',
            height: '450',
            extension: 'jpg',
          })}
        />
      )}
      <div className={s.centered}>
        <header className={s.header}>
          {logo && (
            <Image
              className={s.logo}
              webpUrl={getSignedResizedUrl({
                url: logo.fallbackUrl,
                width: '190',
                height: '190',
                extension: 'webp',
              })}
              url={
                logo.fallbackUrl !== ''
                  ? getSignedResizedUrl({
                      url: logo.fallbackUrl,
                      width: '190',
                      height: '190',
                      extension: 'jpg',
                    })
                  : '/img/placeholder.svg'
              }
            />
          )}
          <div className={s.title}>
            <h1>{name}</h1>
            {shortDescription && shortDescription !== '' && (
              <p className={s.brandDescription}>{shortDescription}</p>
            )}
            {city && country && <p>{`${city}, ${country}`}</p>}
          </div>
        </header>
        <div className={s.section}>
          <HorizontalTabs tabsData={tabsData} />
        </div>
        <div className={s.products}>
          {status === BRAND_STATUS.ACTIVE && products.length > 0 && (
            <ProductList title="Товары" products={products} columns={3} />
          )}

          <footer className={s.footer}>
            {statusInLaw === BRAND_STATUS_IN_LAW.ARTISAN ? (
              <div className={s.footerColumn}>
                {ownerName && (
                  <p className={s.footerText}>Продавец: {ownerName}</p>
                )}
              </div>
            ) : (
              <>
                <div className={s.footerColumn}>
                  <p className={s.footerText}>
                    {nameInLaw && nameInLaw} <br />
                    {addressInLaw && `Юр. адрес: ${addressInLaw}`} <br />
                    {unp && `УНП: ${unp}`}
                  </p>
                </div>
                <div className={s.footerColumn}>
                  <p className={s.footerText}>
                    {regNum && `Свидетельство о регистрации ${regNum}`}{' '}
                    {regDate && `от ${regDate}`} <br />
                    {regDepartment &&
                      `Регистрирующий орган: ${regDepartment}`}{' '}
                    <br />
                    {tradeDepartmentRegDate &&
                      `Зарегистрирован в торговом реестре ${tradeDepartmentRegDate}`}
                  </p>
                </div>
              </>
            )}
          </footer>
        </div>
      </div>
    </div>
  );
};

export default BrandPage;
