exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2Dxxt,._3w-Wv{position:relative}._3w-Wv{margin:0 0 65px}._2uSzA{margin:0 0 50px;padding:0;color:var(--color-dark);font-size:14px;font-size:.875rem;text-transform:uppercase;font-weight:700;letter-spacing:var(--letter-spacing)}._2uSzA span{display:inline-block;width:130px;margin-right:20px;text-align:center}@media (max-width:992px){._2uSzA span{width:auto}}._2t3as{margin:0 0 30px;padding:0 0 0 150px;color:var(--color-error);font-size:14px;font-size:.875rem;font-weight:700}@media (max-width:992px){._2t3as{padding:0}}._1K5zj{position:relative;padding-left:150px}@media (max-width:992px){._1K5zj{padding:0}}.mavHG{display:flex;flex-wrap:wrap;position:relative;margin-top:30px}.jjDKB{color:var(--color-gray);font-weight:700;font-size:14px;font-size:.875rem;text-decoration:none;transition:all .1s ease-out}.jjDKB svg{margin-right:10px}.jjDKB svg path{transition:all .1s ease-out}.jjDKB:hover{color:var(--color-dark)}.jjDKB:hover svg path{stroke:var(--color-dark)}._8adR3{position:relative;margin:30px 0 60px}._8adR3 p{margin:0 0 10px;padding:0}._3s6Oi{padding:0;margin:20px 0;font-size:14px;font-size:.875rem;color:var(--color-gray)}._2IdYl{display:flex;justify-content:space-between;margin:0 0 30px}.hes0f{width:100%}._38Sin{width:355px}@media (max-width:992px){._38Sin{width:calc(50% - 10px)}}.QfLTp{margin-top:30px;margin-left:auto;text-align:right}@media (max-width:992px){.QfLTp{width:100%;margin-left:0}}.QfLTp button{width:360px}@media (max-width:992px){.QfLTp button{width:100%}}._3YPsx{margin:15px 0 0 30px;color:var(--color-error)}._3YPsx,._35_Ay{font-size:14px;font-size:.875rem}._35_Ay{display:flex;align-items:center;padding:0;margin:0;text-transform:uppercase;color:var(--color-dark);letter-spacing:var(--letter-spacing);border:none;background:transparent;outline:none;cursor:pointer}._35_Ay svg{margin-right:15px}", ""]);
// Exports
exports.locals = {
	"root": "_2Dxxt",
	"section": "_3w-Wv",
	"sectionTitle": "_2uSzA",
	"sectionWarning": "_2t3as",
	"sectionContent": "_1K5zj",
	"images": "mavHG",
	"photoInfoLink": "jjDKB",
	"note": "_8adR3",
	"noteSmall": "_3s6Oi",
	"formSection": "_2IdYl",
	"inputLarge": "hes0f",
	"inputMedium": "_38Sin",
	"formSectionControls": "QfLTp",
	"formError": "_3YPsx",
	"removeButton": "_35_Ay"
};